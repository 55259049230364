import React, { useEffect, useState } from 'react';
import FormikInput from '../../../../components/Form/Formik/FormikInput';
import { Select } from '../../../../components/Form/Select';
import { ApplicationTypes } from '../consts/VersionConstants';
import FormikTextArea from '../../../../components/Form/Formik/FormikTextArea';

export default function PackageDetails({ formikProps }) {
  const includedAppTypes = () => {
    if (ApplicationTypes)
      return ApplicationTypes.sort((a, b) => a.name.localeCompare(b.name)).filter(
        (p) =>
          formikProps.values.changelog[p.value].included &&
          formikProps.values.changelog[p.value].isReleasing
      );
    else return [];
  };
  const [currentPackage, setCurrentPackage] = useState(formikProps.values.packageId);
  const [appSelected, setAppSelected] = useState({ name: '', value: '' });
  const [subModuleSelectedIndex, setSubModuleSelectedIndex] = useState(0);

  const changeCurrentAppSelected = (key) => {
    if (key) {
      const newIndex = ApplicationTypes.findIndex((x) => x.value === key);
      setAppSelected(ApplicationTypes[newIndex]);
    }
  };

  const getSubModulesOptions = (inputData) => {
    if (inputData && inputData.length > 0) {
      return inputData.map((subModule, index) => {
        return {
          value: index,
          name: subModule.name
        };
      });
    }
    return [];
  };

  const filteredApplications = (formikProps) => {
    let result = [];
    if (formikProps.values) {
      ApplicationTypes.forEach((x) => {
        if (formikProps.values.changelog[x.value].isReleasing) {
          result.push(x);
        }
      });
    }
    return result;
  };

  useEffect(() => {
    if (
      includedAppTypes()[0] &&
      (appSelected.value === '' || currentPackage !== formikProps.values.packageId)
    ) {
      setAppSelected(includedAppTypes()[0]);
      setCurrentPackage(formikProps.values.packageId);
    }
  }, [includedAppTypes]);

  return (
    <>
      <div className="mt-10 ml-3 pb-10">
        <div className="grid lg:grid-cols-9">
          <div className="my-auto">
            <label className="text-base font-medium text-gray-900">Application</label>
          </div>
          <div className="mt-2 col-span-2 mr-5">
            <Select
              name={appSelected.name + '_name'}
              disabled={filteredApplications(formikProps).length === 0}
              options={filteredApplications(formikProps)}
              selectedValue={appSelected.value}
              onChange={(e) => changeCurrentAppSelected(e)}
            />
          </div>
          <div className="my-auto ml-5">
            <label className="text-base font-medium text-gray-900">Version</label>
          </div>
          <div className="mt-2 col-span-2 mr-5">
            <FormikInput
              name={'changelog[' + appSelected.value + '].version'}
              disabled={filteredApplications(formikProps).length === 0}
              formikProps={formikProps}
            />
          </div>
          <div className="my-auto ml-10">
            <label className="text-base font-medium text-gray-900">Image</label>
          </div>
          <div className="mt-2 col-span-2 mr-5">
            <FormikInput
              name={'changelog[' + appSelected.value + '].image'}
              disabled={filteredApplications(formikProps).length === 0}
              formikProps={formikProps}
            />
          </div>
        </div>

        {appSelected.value !== '' &&
          formikProps?.values.changelog[appSelected.value]?.subModules &&
          formikProps?.values.changelog[appSelected.value].subModules.length > 0 && (
            <>
              <div className="grid lg:grid-cols-9 mt-3">
                <div className="my-auto">
                  <label className="text-base font-medium text-gray-900">Sub Module</label>
                </div>
                <div className="mt-2 col-span-2 mr-5">
                  <Select
                    name={appSelected.name + '_subModule' + subModuleSelectedIndex}
                    disabled={filteredApplications(formikProps).length === 0}
                    options={getSubModulesOptions(
                      formikProps.values.changelog[appSelected.value].subModules
                    )}
                    onChange={(e) => setSubModuleSelectedIndex(e)}
                  />
                </div>
                <div className="my-auto ml-5">
                  <label className="text-base font-medium text-gray-900">Version</label>
                </div>
                <div className="mt-2 col-span-2 mr-5">
                  <FormikInput
                    name={
                      'changelog[' +
                      appSelected.value +
                      '].subModules[' +
                      subModuleSelectedIndex +
                      '].version'
                    }
                    disabled={filteredApplications(formikProps).length === 0}
                    formikProps={formikProps}
                  />
                </div>
                <div className="my-auto ml-10">
                  <label className="text-base font-medium text-gray-900">Image</label>
                </div>
                <div className="mt-2 col-span-2 mr-5">
                  <FormikInput
                    name={
                      'changelog[' +
                      appSelected.value +
                      '].subModules[' +
                      subModuleSelectedIndex +
                      '].image'
                    }
                    disabled={filteredApplications(formikProps).length === 0}
                    formikProps={formikProps}
                  />
                </div>
              </div>
            </>
          )}

        <div className="grid lg:grid-cols-9 mt-5">
          <div className="my-auto">
            <label className="text-base font-medium text-gray-900">Headline</label>
          </div>
          <div className="col-span-8 mr-5">
            <FormikInput
              name={'changelog[' + appSelected.value + '].headline'}
              disabled={filteredApplications(formikProps).length === 0}
              formikProps={formikProps}
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-9 mt-5">
          <div className="my-auto">
            <label className="text-base font-medium text-gray-900">Dependencies</label>
          </div>
          <div className="col-span-8 mr-5">
            <FormikInput
              name={'changelog[' + appSelected.value + '].dependencies'}
              disabled={filteredApplications(formikProps).length === 0}
              formikProps={formikProps}
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-9 mt-5">
          <div className="mt-1">
            <label className="text-base font-medium text-gray-900">Changelog</label>
          </div>
          <div className="col-span-5">
            <FormikTextArea
              name={'changelog[' + appSelected.value + '].changelogNotes'}
              rows={7}
              disabled={filteredApplications(formikProps).length === 0}
              onChange={(v) => {
                formikProps.setFieldValue(
                  'changelog[' + appSelected.value + '].changelogNotes',
                  v.target.value
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
