import { ErrorMessage } from 'formik';

export default function FormikErrorMessage({ name }) {
  return (
    <ErrorMessage name={name}>
      {(msg) => (
        <div className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {msg}
        </div>
      )}
    </ErrorMessage>
  );
}
