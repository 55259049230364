import { post, get, BaseUrl, setStatusApiKey, put } from './api';

export const getServices = async () => {
  setStatusApiKey();
  return await get(`Services/all`, BaseUrl.Status);
};

export const getIncident = async (id) => {
  return await get(`Incidents/${id}`, BaseUrl.Status);
};

export const searchIncidents = async (data) => {
  return await post(`Incidents/search`, data, {}, BaseUrl.Status);
};

export const createIncident = async (data) => {
  setStatusApiKey();
  return await post(`Incidents`, data, {}, BaseUrl.Status);
};

export const updateIncident = async (data) => {
  setStatusApiKey();
  return await put(`Incidents`, data, {}, BaseUrl.Status);
};


