import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useClassNames } from '../../../../hooks/useClassNames';
import { initTabs } from '../consts/VersionConstants';
import { FormikSubmitButton } from '../../../../components/Form/Formik/FormikSubmit';
import PackageGeneralInfo from './PackageGeneralInfo';
import { Button } from '../../../../components';
import PackageDetails from './PackageDetails';
import PackageCustomer from './PackageCustomer';
import PackageNotification from './PackageNotification';

export default function PackageElement({
  availableCustomers,
  currentPackageName,
  packageElementData,
  handleOnSubmit
}) {
  const [tabs, setTabs] = useState(JSON.parse(JSON.stringify(initTabs)));

  const { classNames } = useClassNames();

  const handleOnTabItemClick = (tab, e) => {
    e.preventDefault();

    setTabs((state) =>
      state.map((s) => {
        if (s.name === tab.name) {
          s.current = true;
        } else {
          s.current = false;
        }

        return s;
      })
    );
  };

  const onDelete = (formikProps) => {
    let deleteData = formikProps.values;
    deleteData.packageState = 2;
    formikProps.setFieldValue('packageState', 2);
    handleOnSubmit(formikProps.values);
  };

  const validationSchema = Yup.object().shape({
    packageVersion: Yup.string().required('Required'),
    packageDate: Yup.string().required('Required')
  });

  useEffect(() => {
    if(!currentPackageName) {
      setTabs(JSON.parse(JSON.stringify(initTabs)));
    }
  }, [currentPackageName])

  return (
    <>
      <div className="border-b border-gray-200 sm:pb-0">
        <div className="mb-4 pb-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Package Information</h3>
          </div>
        </div>
        <div className="flex flex-col pb-5 mb-10 border-b border-gray-200">
          <h1 className="text-3xl font-bold">
            {currentPackageName ? currentPackageName : 'New Package Draft'}
          </h1>
        </div>

        <nav className="-mb-px flex space-x-8 mt-4 sm:mt-8">
          {tabs.filter(x => currentPackageName || x.name === 'Package').map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.current
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
              )}
              aria-current={tab.current ? 'page' : undefined}
              onClick={(e) => handleOnTabItemClick(tab, e)}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
      <Formik
        initialValues={packageElementData}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {(formikProps) => (
          <>
            {tabs.find((tab) => tab.current).name === 'Notification' && (
              <>
                <PackageNotification
                  elementFormikProps={formikProps}
                  packageData={packageElementData}
                />
              </>
            )}
            <Form>
              {tabs.find((tab) => tab.current).name === 'Package' && (
                <>
                  <PackageGeneralInfo formikProps={formikProps} />
                </>
              )}
              {tabs.find((tab) => tab.current).name === 'Details' && (
                <>
                  <PackageDetails formikProps={formikProps} />
                </>
              )}
              {tabs.find((tab) => tab.current).name === 'Customers' && (
                <>
                  <PackageCustomer
                    formikProps={formikProps}
                    availableCustomers={availableCustomers}
                  />
                </>
              )}
              <div className="grid pt-5 border-t border-gray-200">
                <div className="ml-auto grid grid-cols-2">
                  <div>
                    {packageElementData.packageId && (
                      <Button
                        variant={Button.variants.secondary}
                        onClick={() => onDelete(formikProps)}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                  <div className="ml-3">
                    <FormikSubmitButton id="save" text="Save" formikProps={formikProps} />
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
