import React, { useEffect, useState } from 'react';
import { TextInput } from '../../../../../components/Form/TextInput';
import { useClassNames } from '../../../../../hooks/useClassNames';
import styles from './../PackagesList.module.scss';

export default function CustomerSelectionSelected({ selectedCustomers, onRemoveCustomer }) {
  const [searchPackageValue, setSearchPackageValue] = useState('');
  const [packageListSelectionData, setPackageListSelectionData] = useState(null);

  const { classNames } = useClassNames();

  useEffect(() => {
    setPackageListSelectionData(selectedCustomers.sort((a, b) => a.name.localeCompare(b.name))[0]);
  }, []);

  return (
    <>
      <div className="px-5 grid grid-cols-3">
        <div className="col-span-3 my-auto">
          <p className="leading-5 font-semibold">Selected Customers</p>
          <p className="leading-5 font-thin font-sm mt-2">Click twice on element to execute removal action</p>
        </div>
      </div>

      
      <div className="mt-5 px-5">
        <TextInput
          name="searchCustomer"
          placeholder="Filter selected Customers..."
          value={searchPackageValue}
          onChange={(value) => setSearchPackageValue(value)}
        />
      </div>

      <div className={classNames(selectedCustomers.length > 10 && 'overflow-y-auto')}>
        <ul
          className={classNames('mt-4 px-5 divide-y divide-gray-200 overflow-y-auto', styles.listSmaller)}
        >
          {selectedCustomers
            .filter((c) => {
              return searchPackageValue.length
                ? c.name.toLowerCase().includes(searchPackageValue.toLowerCase())
                : true;
            })
            .filter((c, index) => (index > 50 ? false : true))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((c, index) => (
              <li
                key={c.packageId}
                className={classNames(
                  'px-4 py-4 cursor-pointer hover:bg-gray-100 hover:text-gray-900',
                  index % 2 && 'bg-gray-50'
                )}
                onClick={(event) => onRemoveCustomer(event, c.customerId)}
              >
                <div className="flex items-center space-x-4">
                  <p className="truncate text-sm font-medium text-gray-900">{c.name}</p>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
