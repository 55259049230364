import React, { useEffect, useState } from 'react';
import { DateTimeParser, useToastAction } from '@metaforcelabs/metaforce-core';
import PackagesList from './components/PackagesList';
import PackageElement from './components/PackageElement';
import { Loader } from '../../../components';
import { initialPackageDetails } from './consts/VersionConstants';
import {
  CreateReleasePackage,
  GetCustomersList,
  GetReleasePackage,
  GetReleasePackagesSimplifiedList,
  UpdateReleasePackage
} from '../../../api/releasePackages';

export default function Version() {
  const [packagesListData, setPackagesListData] = useState([]);
  const [packageElementData, setPackageElementData] = useState(initialPackageDetails);
  const [customersList, setCustomersList] = useState([]);


  const getPackagesListDataAction = useToastAction();
  const getReleasePackageDataAction = useToastAction();
  const getCustomersListAction = useToastAction();
  const saveReleasePackage = useToastAction();
  const createReleasePackage = useToastAction();

  const fetchPackagesList = () => {
    getPackagesListDataAction.execute(async () => {
      const response = await GetReleasePackagesSimplifiedList();
      if (
        packagesListData.length === 0 &&
        response.length !== 0 &&
        packageElementData.packageId === null
      ) {
        fetchReleasePackage(response[0].packageId);
      }
      setPackagesListData(response);
    }, 'Failed to load Release Packages list data');
  };

  const fetchCustomersList = () => {
    getCustomersListAction.execute(async () => {
      const response = await GetCustomersList();
      setCustomersList(response);
    }, 'Failed to load Customers List');
  };

  const fetchReleasePackage = (packageId) => {
    getReleasePackageDataAction.execute(async () => {
      const formData = await GetReleasePackage(packageId);
      setPackageElementData(formData);
    }, 'Failed to load Release Package data');
  };

  const getCurrentPackageName = () => {
    if (packagesListData.length > 0 && packageElementData.packageId !== null)
      return packagesListData.find((x) => x.packageId === packageElementData.packageId)?.name;
    else return '';
  };

  const handleOnSubmit = async (values, actions) => {
    values.packageDate = DateTimeParser.toDateObject(values.packageDate);
    if (values.packageId !== null) {
      saveReleasePackage.execute(async () => {
        await UpdateReleasePackage(values.packageId, values);
        setPackageElementData(values);
        fetchPackagesList();
      }, 'Failed to save', 'Saved');
    } else {
      createReleasePackage.execute(async () => {
        const formData = await CreateReleasePackage(values);
        setPackageElementData(formData);
        fetchPackagesList();
      }, 'Failed to create', 'Release Package Version has been created');

    }
  };

  const handleOnSelectPackage = async (selectedPackage) => {
    fetchReleasePackage(selectedPackage.packageId);
  };

  const handleCreateNewPackage = () => {
    initialPackageDetails.packageDate = new Date();
    setPackageElementData(initialPackageDetails);
  };

  useEffect(() => {
    fetchPackagesList();
    fetchCustomersList();
  }, []);

  return (
    <div>
      <div className="flex flex-col pb-5 mb-10 border-b border-gray-200">
        <h1 className="text-3xl font-bold">List of packages</h1>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">Manage release notes</p>
      </div>

      <div className="mx-auto lg:grid  lg:grid-cols-12 lg:gap-8">
        <div className="mb-4 lg:mb-0 lg:col-span-3 lg:block xl:col-span-3 py-5 bg-white shadow shadow-sm rounded-lg">
          {packagesListData.length > 0 && (
            <PackagesList
              packagesListData={packagesListData}
              selectedItem={packageElementData.packageId}
              onSelectPackage={handleOnSelectPackage}
              handleCreateNewPackage={handleCreateNewPackage}
            />
          )}
        </div>

        <main className="relative lg:col-span-9 xl:col-span-9 p-5 bg-white shadow shadow-sm rounded-lg overflow-hidden">
          {getReleasePackageDataAction.isExecuting && (
            <Loader overlay className="absolute z-10 inset-0 flex items-center" />
          )}

          {packagesListData && packageElementData && (
            <PackageElement
              availableCustomers={customersList}
              currentPackageName={getCurrentPackageName()}
              packageElementData={packageElementData}
              handleOnSubmit={handleOnSubmit}
            />
          )}
        </main>
      </div>
    </div>
  );
}
