import React, { Fragment, useEffect, useRef, useState } from 'react';
import { PageHeader, stringToLocaleDateTimeString, useToastAction } from '@metaforcelabs/metaforce-core';
import { Form, Formik } from 'formik';
import FormikInput from '../../../components/Form/Formik/FormikInput';
import { Button } from '../../../components';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { FormikCheckbox } from '../../../components/Form/Formik/FormikCheckbox';
import _ from 'lodash';
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow
} from '../../../components/Table';
import { getIncident, getServices, updateIncident } from '../../../api/status';
import * as Constants from '../../../utils/constants';
import LogEntryEditor from './components/LogEntryEditor';
import * as Yup from 'yup';

const affectedServicePrefix = 'ASP';

const formInitialValues = {
  title: '',
};

const Incident = () => {
  const [affectedServices, setAffectedServices] = useState([]);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [showLogEntryEditor, setShowLogEntryEditor] = useState(false);
  const [logEntry, setLogEntry] = useState({
    status: 0,
    title: '',
    createdDate: new Date(),
  });
  const [logEntries, setLogEntries] = useState([]);
  const { incidentId } = useParams();

  const history = useHistory();

  const incidentAction = useToastAction();

  const updateIncidentAction = useToastAction();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
  });

  const fetchIncident = () => {
    incidentAction.execute(async () => {
      if (!incidentId) return;

      const [getIncidentResult, getServicesResult] = await Promise.all([
        getIncident(incidentId),
        getServices(),
      ]);

      const newFormValues = {
        title: getIncidentResult.title,
      };

      const newAffectedService = {};
      getServicesResult.services && Object.keys(getServicesResult.services)
      .forEach((service) => {
        newAffectedService[service] = getServicesResult.services[service];
        newFormValues[`${affectedServicePrefix}${service}`] = false;
      });
      setAffectedServices(newAffectedService);
      
      getIncidentResult.affectedServices.forEach((service) => {
        newFormValues[`${affectedServicePrefix}${service}`] = true;
      })
      setFormValues(newFormValues);

      setLogEntries(getIncidentResult.logs);

    }, 'Failed to load data');
  };

  const handleOnSubmit = (values, actions) => {
    updateIncidentAction.execute(
      async () => {
        const incident = { 
          title: values.title,
          id: incidentId,
          logs: _.cloneDeep(logEntries),
          affectedServices: [],
        };

        Object.keys(values)
          .filter(propName => propName.startsWith(affectedServicePrefix) 
            && values[propName] === true)
          .forEach(propName => {
            try {
              let serviceString = propName.substring(affectedServicePrefix.length);
              let service = parseInt(serviceString);
              incident.affectedServices.push(service);
            } catch (err) {
            }
          })

        await updateIncident(incident);

        history.push('/admin/status');

        actions.setSubmitting(false);
      },
      'Failed to save data',
      'Save successful'
    );
  };

  const handleOnCancel = () => {
    history.push('/admin/status');
  };

  const handleOnSaveLogEntry = (data) => {
    setShowLogEntryEditor(false);

    const clonedLogEntries = _.cloneDeep(logEntries);
    const clonedLogEntry = _.cloneDeep(data);
    clonedLogEntries.unshift(clonedLogEntry);
    setLogEntries(clonedLogEntries);
  }

  useEffect(() => {
    fetchIncident();
  }, []);

  return (
    <>
      <div className="space-y-8">
        <PageHeader title={'Incident'} />

        <div className="bg-white py-6 px-4 sm:p-6 shadow sm:rounded-md sm:overflow-hidden">
          <Formik 
            initialValues={formValues} 
            onSubmit={handleOnSubmit} 
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            {(props) => (
              <Form>
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="md:grid md:grid-cols-4 md:gap-6">
                    <div className="md:col-span-1">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">Title</h3>
                    </div>

                    <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
                      <FormikInput label="" name="title" formikProps={props} required={true} />
                    </div>
                  </div>

                  <div className="pt-8 md:grid md:grid-cols-4 md:gap-6">
                    <div className="md:col-span-1">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">Affected Services</h3>
                    </div>
                    <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        {Object.keys(affectedServices).map((service) => {
                          return (
                            <div className="sm:col-span-1" key={service}>
                              <FormikCheckbox
                                label={affectedServices[service]}
                                name={`${affectedServicePrefix}${service}`}
                                formikProps={props}
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="pt-8 md:grid md:grid-cols-4 md:gap-6">
                    <div className="md:col-span-1">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">Logs</h3>
                    </div>
                    <div className="mt-5 space-y-3 md:col-span-3 md:mt-0">
                      <Button
                        variant={Button.variants.secondary}
                        onClick={() => {
                          setShowLogEntryEditor(true)
                          setLogEntry({
                            status: 0,
                            title: '',
                            createdDate: new Date(),
                          })
                        }}
                      >
                        Add Log Entry
                      </Button>
                      <div className="relative">
                        <Table>
                          <TableHead>
                            <TableHeader>Title</TableHeader>
                            <TableHeader>Status</TableHeader>
                            <TableHeader>Created</TableHeader>
                          </TableHead>
                          <TableBody>
                            {logEntries.map((row, index) => (
                              <Fragment key={index}>
                                <TableRow className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                                  <TableColumn 
                                    style={{
                                      maxWidth: '300px',
                                      whiteSpace: 'normal',
                                    }}
                                  >
                                    <div className="font-medium text-sm text-indigo-600">
                                      {row.title}
                                    </div>
                                  </TableColumn>
                                  <TableColumn>
                                    {Constants.incidentLogStatus[row.status] ? Constants.incidentLogStatus[row.status] : ''}
                                  </TableColumn>
                                  <TableColumn>
                                    {stringToLocaleDateTimeString(row.createdDate)}
                                  </TableColumn>
                                </TableRow>
                              </Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-end">
                      <Button
                        variant={Button.variants.secondary}
                        onClick={() => handleOnCancel(props.resetForm)}
                      >
                        Cancel
                      </Button>

                      <FormikSubmitButton
                        id="save"
                        text="Save"
                        className="ml-2 i"
                        formikProps={props}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <LogEntryEditor
        open={showLogEntryEditor}
        setOpen={setShowLogEntryEditor}
        logEntry={logEntry}
        onSave={(v) => handleOnSaveLogEntry(v)}
      />
    </>
  );
};

export default Incident;
