import { useState } from 'react';
import { useClassNames } from '../../hooks/useClassNames';

export const TextInput = ({
  name,
  label = null,
  value,
  onChange,
  type = null,
  placeholder = null,
  required = false,
  onKeyPress = null,
  validation: validate,
  horizontal = false
}) => {
  const [blurred, setBlurred] = useState(false);
  const classes = useClassNames();

  let hasValidationError = false;
  if (blurred && validate) {
    hasValidationError = !validate(value);
  }

  const classNames = classes.classNames(
    'shadow-sm block w-full sm:text-sm border-gray-300 rounded-md',
    hasValidationError
      ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
      : 'focus:ring-gray-400 focus:border-gray-400'
  );

  return (
    <>
      {label && (
        <div className={classes.classNames(horizontal ? 'flex items-center mt-1' : '')}>
          <label
            htmlFor={name}
            className={classes.classNames(
              horizontal ? 'flex-1' : '',
              'block text-sm font-medium text-gray-700'
            )}
          >
            {label}
            {required === true && <span className="text-red-400"> *</span>}
          </label>
          <div className={classes.classNames(horizontal ? 'flex-1' : 'mt-1')}>
            <input
              type={type || 'text'}
              name={name}
              value={value || ''}
              onChange={(evt) => onChange(evt.target.value, name, evt)}
              onKeyPress={(evt) => onKeyPress && onKeyPress(evt)}
              className={classNames}
              placeholder={placeholder}
              onBlur={() => setBlurred(true)}
            />
          </div>
        </div>
      )}
      {!label && (
        <input
          type={type || 'text'}
          name={name}
          value={value || ''}
          onChange={(evt) => onChange(evt.target.value, name)}
          onKeyPress={(evt) => onKeyPress && onKeyPress(evt)}
          className={classNames}
          placeholder={placeholder}
          onBlur={() => setBlurred(true)}
        />
      )}
    </>
  );
};
