import { TextInput } from '../../../../../components/Form/TextInput';
import React, { useEffect, useState } from 'react';
import { useClassNames } from '../../../../../hooks/useClassNames';
import styles from './../PackagesList.module.scss';

export default function CustomerSelectionList({ customersList, selectedCustomers, onAddCustomer }) {
  const [searchPackageValue, setSearchPackageValue] = useState('');
  const [packageListSelectionData, setPackageListSelectionData] = useState(null);

  const { classNames } = useClassNames();

  useEffect(() => {
    setPackageListSelectionData(selectedCustomers.sort((a, b) => a.name.localeCompare(b.name))[0]);
  }, []);

  return (
    <>
      <div className="grid grid-cols-3">
        <div className="col-span-3 my-auto">
          <p className="leading-5 font-semibold font-large">Find Customer to add</p>
          <p className="leading-5 font-thin font-sm mt-2">Click twice on element to execute addition action</p>
        </div>
      </div>

      <div className="mt-5">
        <TextInput
          name="searchCustomer"
          placeholder="Find Customers that can be added..."
          value={searchPackageValue}
          onChange={(value) => setSearchPackageValue(value)}
        />
      </div>

      <div className={classNames(customersList.length > 10 && 'overflow-y-auto')}>
        <ul
          className={classNames('mt-4 divide-y divide-gray-200 overflow-y-auto', styles.listSmaller)}
        >
          {customersList
            .filter((c) => {
              return searchPackageValue.length
                ? c.name.toLowerCase().includes(searchPackageValue.toLowerCase())
                : true;
            })
            .filter((c) => {
                return selectedCustomers.findIndex(x => x.customerId === c.customerId) === -1;
            })
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((c, index) => (
              <li
                key={c.packageId}
                className={classNames(
                  'px-4 py-4 cursor-pointer hover:bg-gray-100 hover:text-gray-900',
                  index % 2 && 'bg-gray-50'
                )}
                onClick={(event) => onAddCustomer(event, c)}
              >
                <div className="flex items-center space-x-4">
                  <p className="truncate text-sm font-medium text-gray-900">{c.name}</p>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
