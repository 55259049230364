import { ExclamationIcon, InformationCircleIcon } from '@heroicons/react/outline';
import React from 'react';

const LicenseNotifications = ({ isNewLicenseMode, isDowngraded, isGoingToDowngrade }) => {
  return (
    <>
      {isNewLicenseMode ? (
        <div className="mb-4 rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                You are in new license preview mode. What is presented contain all informations that
                new user license will contain after you save it
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="mb-4 rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                You are in current license preview mode. What is presented contain all informations
                about current user license
              </p>
            </div>
          </div>
        </div>
      )}

      {isDowngraded ? (
        <div className="mb-4 rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                There is a new downgrade license for this customer
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  From the next billing period, a new license with a lower tire will be valid for
                  this customer. Due to the fact that only one new license can exist If you will
                  create a next one you will override the existing new one.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        isGoingToDowngrade && (
          <div className="mb-4 rounded-md bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">Information</h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>
                    You are going to downgrade user license tire. Please be aware that current
                    license will be valid till the end of current billing period.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default LicenseNotifications;
