import { post, get } from './api';

export const GetReleasePackagesSimplifiedList = async () => {
  return await get(`Version/ReleasePackagesSimplifiedList`);
};

export const GetReleasePackages = async () => {
  return await get(`Version/ReleasePackages`);
};

export const GetReleasePackage = async (packageId) => {
  return await get(`Version/ReleasePackages/${packageId}`);
};

export const UpdateReleasePackage = async (packageId, data) => {
  return await post(`Version/ReleasePackage/${packageId}`, data);
};

export const CreateReleasePackage = async (data) => {
  return await post(`Version/ReleasePackage`, data);
};

export const GetCustomersList = async () => {
  return await get(`Version/AvailableCustomers`);
};

export const SendNotification = async (request) => {
  return await post(`Version/SendNotification`, request);
};

export const GetNotificationPreview = async (request) => {
  return await post(`Version/GetNotificationPreview`, request);
};