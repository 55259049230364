
import { Form, Formik } from 'formik';
import { Button } from '../../../../components';
import Modal from '../../../../components/Modal';
import * as Yup from 'yup';
import { FormikSubmitButton } from '../../../../components/Form/Formik/FormikSubmit';
import * as Constants from '../../../../utils/constants';
import { FormikSelectInput } from '../../../../components/Form/Formik/FormikSelect';
import FormikTextArea from '../../../../components/Form/Formik/FormikTextArea';

const LogEntryEditor = ({ open, setOpen, logEntry, onSave }) => {
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Required'),
    });

    const handleOnSubmit = async (values) => {
        onSave(values);
        setOpen(false);
    }

    return (
        <Modal
            isOpen={open}
            onClose={() => setOpen(false)}
            size={'large'}
            title="Incident Log Entry"
        >
            <Formik
                initialValues={logEntry}
                validationSchema={validationSchema}
                onSubmit={handleOnSubmit}
                enableReinitialize={true}
            >
                {(props) => (
                    <Form>
                        <div className="overflow-x-auto mt-5">
                            <div>
                                <FormikSelectInput
                                    label="Status"
                                    name="status"
                                    options={Object.keys(Constants.incidentLogStatus).map(key => {
                                        return {
                                            name: Constants.incidentLogStatus[key],
                                            value: key
                                        };
                                    })}
                                    formikProps={props}
                                    required={true}
                                />
                            </div>
                            <div className="mt-5">
                                <FormikTextArea
                                    label="Title"
                                    name="title"
                                    rows={5}
                                    required={true}
                                    onChange={(v) => {
                                        props.setFieldValue(
                                            'title',
                                            v.target.value
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex justify-end mt-5 sm:mt-6">
                            <Button
                                variant={Button.variants.secondary}
                                className="mr-3"
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </Button>

                            <FormikSubmitButton
                                id="logEntrySave"
                                text="Save"
                                className="ml-2 i"
                                formikProps={props}
                            />
                        </div>
                    </Form>
                )}
            </Formik>

        </Modal>
    );
};

export default LogEntryEditor;
