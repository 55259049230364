const RadioGroup = ({ label, children }) => {
  return (
    <div>
      <label className="text-base font-medium text-gray-900">{label}</label>
      <fieldset className="mt-2">{children}</fieldset>
    </div>
  );
};

export default RadioGroup;
