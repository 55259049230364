import moment from 'moment';
import 'moment/locale/nb';
import 'moment/locale/da';
import 'moment/locale/sv';
import 'moment/locale/es';
import 'moment/locale/ar';

const DateTimeParser = {
  toLocalJSONFormat(date) {
    const parsedDate = new Date(date);
    const offset = Math.abs(parsedDate.getTimezoneOffset() / 60);
    const localJSONFormat = parsedDate.setHours(parsedDate.getHours() + offset);

    return new Date(localJSONFormat).toJSON();
  },

  setSOD(date) {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    dateObj.setHours(0, 0, 0, 0);
    return dateObj;
  },

  setEOD(date) {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    dateObj.setHours(23, 59, 59);
    return dateObj;
  },

  toLocaleDateString(date) {
    if (date === null) {
      return '';
    }

    const dateObj = typeof date === 'string' ? new Date(date) : date;

    return moment(dateObj, 'L').locale(this.getCurrentCultureCode()).format('L');
  },

  toLocaleTimeString(jsonFormatDateString) {
    const date = new Date(jsonFormatDateString);
    return moment(date).locale(this.getCurrentCultureCode()).format('LT');
  },

  toLocaleDateTimeString(jsonFormatDateString) {
    return (
      this.toLocaleDateString(jsonFormatDateString) +
      ' ' +
      this.toLocaleTimeString(jsonFormatDateString)
    );
  },

  toDateObject(value) {
    return new Date(moment(value).weekYear(), moment(value).month(), moment(value).date());
  },

  toValueOf(date) {
    const localeFormat = moment.localeData(this.getCurrentCultureCode()).longDateFormat('L');
    return moment(date, localeFormat).valueOf();
  },

  toDateFormat(date, format) {
    const localeFormat = moment.localeData(this.getCurrentCultureCode()).longDateFormat('L');
    return moment(date, localeFormat).format(format);
  },

  addDays(daysNumber) {
    moment.locale(this.getCurrentCultureCode());
    return moment().add(daysNumber, 'days').format('L');
  },

  addYears(yearsNumber) {
    moment.locale(this.getCurrentCultureCode());
    return moment().add(yearsNumber, 'years').format('L');
  },

  subtractYears(yearsNumber) {
    moment.locale(this.getCurrentCultureCode());
    return moment().subtract(yearsNumber, 'years').format('L');
  },

  add(startDate, amount, unit, format = 'L') {
    const localeFormat = moment.localeData(this.getCurrentCultureCode()).longDateFormat(format);

    return moment(startDate, localeFormat).add(amount, unit).format(format);
  },

  isValidFormat(date, format) {
    const localeFormat = moment.localeData(this.getCurrentCultureCode()).longDateFormat(format);

    if (moment(date, localeFormat, true).isValid()) {
      return true;
    }

    return false;
  },

  isSame(dateOne, dateTwo, unit) {
    if (moment(dateOne).isSame(dateTwo, unit || 'day')) {
      return true;
    }

    return false;
  },

  isAfter(date, unit) {
    if (moment(date).isAfter(moment(), unit || 'day')) {
      return true;
    }

    return false;
  },

  isAfterDate(afterDate, date, unit) {
    if (moment(afterDate).isAfter(moment(date), unit || 'day')) {
      return true;
    }

    return false;
  },

  getFormat(format) {
    moment.locale(this.getCurrentCultureCode());

    const localeData = moment.localeData();

    return localeData.longDateFormat(format);
  },

  subtract(startDate, amount, unit) {
    const localeFormat = moment.localeData(this.getCurrentCultureCode()).longDateFormat('L');

    return moment(startDate, localeFormat).subtract(amount, unit);
  },

  getCurrentCultureCode() {
    return 'en';
  }
};
export default DateTimeParser;
