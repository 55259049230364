import { get, post, put, destroy, BaseUrl } from './api'

export const getAllVideos = async () => {
  return await get(`Videos`, BaseUrl.Centerpoint);
}

export const getVideo = async (id) => {
  return await get(`Videos/${id}`, BaseUrl.Centerpoint);
}

export const createVideo = async (data) => {
    return await post(`Videos`, data, {}, BaseUrl.Centerpoint);
  }

export const updateVideo = async (id, data) => {
  return await put(`Videos/${id}`, data, {}, BaseUrl.Centerpoint);
}

export const deleteVideo = async (id) => {
    return await destroy(`Videos/${id}`, BaseUrl.Centerpoint);
}