
const toUsageThresholds = (values, currency) => {
  return values
    ? values.map((value) => ({
        lower: parseInt(value.lower),
        upper: parseInt(value.upper),
        usageCategories: [
          {
            categoryCode: null,
            usagePrices: [
              {
                usagePrice: value.price ? value.price : null,
                usagePriceCurrency: currency
              }
            ]
          }
        ]
      }))
    : null;
};

const getByCurrency = (data, currency, inputName, outputName) => {
  const result = data.find((d) => d[inputName] === currency);

  if (result) {
    return result[outputName];
  }

  return data[0][outputName];
};

export { getByCurrency, toUsageThresholds };
