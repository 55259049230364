import React, { useEffect, useState } from 'react';
import { PageHeader, useToastAction } from '@metaforcelabs/metaforce-core';
import { getVideo, createVideo, updateVideo, deleteVideo } from '../../../api/videos';
import { Form, Formik } from 'formik';
import FormikInput from '../../../components/Form/Formik/FormikInput';
import { Button } from '../../../components';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { useHistory } from 'react-router-dom';
import FormikTextArea from '../../../components/Form/Formik/FormikTextArea';
import FormikRadio from '../../../components/Form/Formik/FormikRadio';
import RadioGroup from '../../../components/Form/RadioGroup';
import Confirm from "../../../components/Confirm";
import { useModalHelper } from '../../../hooks/useModalHelper';

const formInitialValues = {};

const videoState = {
  Draft: 0,
  Published: 1
};

const Video = ({ match }) => {
  const [video, setVideo] = useState(null);
  const [formValues, setFormValues] = useState(formInitialValues);
  
  let history = useHistory();
  const videoAction = useToastAction();
  const saveVideoAction = useToastAction();
  const confirmDeleteVideo = useModalHelper();

  const fetchVideo = () => {
    videoAction.execute(async () => {
      const videoId = match.params.videoId;
      let getVideoResult;
      if (videoId === 'create') {
        getVideoResult = {
          title: 'New video',
          content: 'Description of the video content',
          url: 'https://iframe.dacast.com/vod/xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx/xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
          state: videoState.Draft,
          priority: 0  
        }
      }
      else {
        getVideoResult = await getVideo(match.params.videoId);
      }
      setVideo(getVideoResult);
      setFormValues({
        title: getVideoResult.title,
        content: getVideoResult.content,
        url: getVideoResult.url,
        state: getVideoResult.state,
        priority: getVideoResult.priority.toString()
      });
    }, 'Failed to load data');
  };

  const handleOnSubmit = (values, actions) => {
    saveVideoAction.execute(
      async () => {
        if (video.id) {
          await updateVideo(video.id, {
            title: values.title,
            content: values.content,
            url: values.url,
            state: values.state,
            priority: parseInt(values.priority)
          });
        }
        else {
          await createVideo({
            title: values.title,
            content: values.content,
            url: values.url,
            state: values.state,
            priority: values.priority
          });
        }
        actions.setSubmitting(false);
        history.push('/admin/videos');
      },
      'Failed to save data',
      'Save successful'
    );
  };

  const handleOnDelete = () => {
    confirmDeleteVideo.open();
  };

  const handleDeleteVideo = async () => {
    if (video.id) {
      await deleteVideo(video.id);
    }
    history.push('/admin/videos');
  }

  const handleOnCancel = () => {
    history.push('/admin/videos');
  };

  useEffect(() => {
    fetchVideo();
  }, []);

  return (
    <>
      <div className="space-y-8">
        <PageHeader title={'Video - ' + formValues.title} />

        {video && (
          <div className="bg-white py-6 px-4 sm:p-6 shadow sm:rounded-md sm:overflow-hidden">
            <Formik initialValues={formValues} onSubmit={handleOnSubmit} enableReinitialize={true}>
              {(props) => (
                <Form>
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="md:grid md:grid-cols-4 md:gap-6">
                      <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Video</h3>
                      </div>

                      <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
                        <FormikInput label="Title" name="title" formikProps={props} />
                        <FormikTextArea label="Content" name="content" formikProps={props} />
                        <FormikInput label="URL" name="url" formikProps={props} />
                        <div style={{width: '80px'}}>
                          <FormikInput label="Priority" name="priority" formikProps={props} />
                        </div>
                        <RadioGroup label="Publication status">
                          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <FormikRadio
                              label="Published"
                              name="state"
                              value={videoState.Published}
                              formikProps={props}
                            />
                            
                            <FormikRadio
                              label="Draft"
                              name="state"
                              value={videoState.Draft}
                              formikProps={props}
                            />
                          </div>
                        </RadioGroup>
                      </div> 
                    </div>

                    <div className="pt-5">
                      <div className="flex justify-between">
                        <div className="inline-block">
                          {video.id && (
                              <Button
                                variant={Button.variants.danger}
                                onClick={handleOnDelete}
                              >
                                Delete
                              </Button>
                          )}
                        </div>
                        <div className="inline-block">
                          <Button
                            variant={Button.variants.secondary}
                            onClick={handleOnCancel}
                          >
                            Cancel
                          </Button>

                          <FormikSubmitButton
                            id="save"
                            text="Save"
                            className="ml-2 i"
                            formikProps={props}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <Confirm
              modalHelper={confirmDeleteVideo}
              title={`Are you sure you want to delete this video?`}
              onConfirm={handleDeleteVideo}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Video;
