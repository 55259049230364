import { Fragment, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow
} from '../../../components/Table';
import { Table as TableCore } from '@metaforcelabs/metaforce-core';
import { Badge, Button } from '../../../components';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import { Form, Formik } from 'formik';
import FormikInput from '../../../components/Form/Formik/FormikInput';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { Link } from 'react-router-dom';
import { useTableActions } from '@metaforcelabs/metaforce-core';
import { useHistory } from "react-router-dom";
import LoginPageSlideElementRightClickMenu from '../../../components/LoginPageSlideElementMenuContext';
import { LoginPageSlideElementContext } from "../../../contexts";
import Confirm from "../../../components/Confirm";
import { useModalHelper } from '../../../hooks/useModalHelper';
import { getAllLoginPageSlides, updateLoginPageSlide, deleteLoginPageSlide } from '../../../api/loginPageSlides';
const dummyLoadData = [{}, {}, {}, {}, {}];

const loginPageSlideState = {
  Draft: 0,
  Published: 1
};

const formInitialValues = {
  searchQuery: ''
};

const LoginPageSlides = () => {
  const [formValues, ] = useState(formInitialValues);
  const [recordsToShow, setRecordsToShow] = useState(dummyLoadData);
  const [showRightClickMenu, setShowRightClickMenu] = useState(false);
  const [rightClickMenuPosition, setRightClickMenuPosition] = useState({ x: 0, y: 0 })
  const [rightClickLoginPageSlideData, setRightClickLoginPageSlideData] = useState(null);
  
  const confirmDeleteLoginPageSlide = useModalHelper()

  const loginPageSlidesAction = useToastAction();
  const tableActions = useTableActions(recordsToShow, 10);

  const history = useHistory();

  const fetchLoginPageSlides = (values) => {
    loginPageSlidesAction.execute(async () => {
      const getLoginPageSlidesResult = await getAllLoginPageSlides();
      const filter = values ? values.searchQuery.toLowerCase() : false;
      setRecordsToShow(getLoginPageSlidesResult
        .filter(x => !filter ||
            (x.title.toLowerCase().includes(filter) || x.content.toLowerCase().includes(filter)))
        .sort((a, b) => b.priority - a.priority));
    }, 'Failed to load login page slides');
  };

  const handleOnAddNewLoginPageSlide = () => {
    history.push('/admin/loginpageslide/create');
  };

  const toggleLoginPageSlideState = () => {
    loginPageSlidesAction.execute(
      async () => {
        await updateLoginPageSlide(rightClickLoginPageSlideData.id, {
          ...rightClickLoginPageSlideData,
          state: rightClickLoginPageSlideData.state ^ 1
        });
        fetchLoginPageSlides(formValues);
      },
      'Failed to save data',
      'Save successful'
    );
  };

  const handleDeleteLoginPageSlide = () => {
    loginPageSlidesAction.execute(
      async () => {
        await deleteLoginPageSlide(rightClickLoginPageSlideData.id);
        fetchLoginPageSlides(formValues);
      },
      'Failed to save data',
      'Save successful'
    );
  }

  const handleOnResetFilter = (resetAction) => {
    resetAction();
    fetchLoginPageSlides();
  };

  const handleOnSubmit = async (values) => {
    fetchLoginPageSlides(values);
  };

  const handleContextMenu = (e) => {
    const loginPageSlideData = recordsToShow.find(v => v.id === e.target.parentNode.dataset.id);
    const tBodyRect = e.target.parentNode.parentNode.parentNode.getBoundingClientRect();
    e.preventDefault();
    setShowRightClickMenu(true);
    setRightClickLoginPageSlideData(loginPageSlideData);
    setRightClickMenuPosition({
      x: e.pageX - tBodyRect.x - document.scrollingElement.scrollLeft,
      y: e.pageY - tBodyRect.y - document.scrollingElement.scrollTop,
    });
  }

  useEffect(() => {
    fetchLoginPageSlides(formValues);
  }, []);

  return (
    <>
      <LoginPageSlideElementContext.Provider
        value={{
          showRightClickMenu,
          setShowRightClickMenu,
          rightClickMenuPosition,
          setRightClickLoginPageSlideData,
          rightClickLoginPageSlideData,
          toggleLoginPageSlideState,
          confirmDeleteLoginPageSlide
        }}
      >
        <div>
          <div className="flex flex-col pb-5 mb-10 border-b border-gray-200">
            <h1 className="text-3xl font-bold">Login Page Slides</h1>
            <p className="mt-2 max-w-4xl text-sm text-gray-500">
              Administer login page slides 
            </p>
          </div>

          <div>
            <Button
              variant={Button.variants.primary} 
              className="mb-8 i"
              onClick={handleOnAddNewLoginPageSlide}
            >Add new</Button>
            <Formik initialValues={formValues} enableReinitialize={true} onSubmit={handleOnSubmit}>
              {(props) => (
                <Form>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-2">
                      <FormikInput label="Search" name="searchQuery" formikProps={props} />
                    </div>

                    <div className="flex items-end ml-auto col-span-3 sm:col-span-2">
                      <Button
                        variant={Button.variants.secondary}
                        onClick={() => handleOnResetFilter(props.resetForm)}
                      >
                        Reset
                      </Button>

                      <FormikSubmitButton
                        id="filter"
                        text="Filter"
                        className="ml-2 i"
                        formikProps={props}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <div className="relative mt-8">
            <Table className="relative">
              <TableHead>
                <TableHeader>Title</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>Content</TableHeader>
                <TableHeader>Priority</TableHeader>
                <TableHeader>Created</TableHeader>
              </TableHead>
              <TableBody onContextMenu={handleContextMenu}>
                {tableActions.pageData?.map((row, index) => (
                  <Fragment key={index}>
                    <TableRow className={index % 2 === 0 ? undefined : 'bg-gray-50'} data-id={row.id}>
                      <TableColumn>
                        <Link
                          to={`/admin/loginpageslide/${row.id}`}
                          className="font-medium text-sm text-indigo-600 hover:text-indigo-500 border-0 shadow-none"
                        >
                          {row.title}
                        </Link>
                      </TableColumn>
                      <TableColumn>{(row.state === loginPageSlideState.Published && (<Badge type="info" text="Published" />))}</TableColumn>
                      <TableColumn className="px-6 py-4 whitespace-pre-wrap text-sm text-gray-500">{row.content}</TableColumn>
                      <TableColumn>{row.priority}</TableColumn>
                      <TableColumn>{stringToLocaleDateTimeString(row.createdDate)}</TableColumn>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
            <LoginPageSlideElementRightClickMenu open={true} />
            <TableCore.Pagination tableActions={tableActions} />
            <Confirm
              modalHelper={confirmDeleteLoginPageSlide}
              title={`Are you sure you want to delete this login page slide?`}
              onConfirm={handleDeleteLoginPageSlide}
            />
          </div>
        </div>
      </LoginPageSlideElementContext.Provider>
    </>
  );
};

export default LoginPageSlides;
