import { get, post, put, destroy, BaseUrl } from './api'

export const getAllLoginPageSlides = async () => {
  return await get(`LoginPageSlides`, BaseUrl.Centerpoint);
}

export const getLoginPageSlide = async (id) => {
  return await get(`LoginPageSlides/${id}`, BaseUrl.Centerpoint);
}

export const createLoginPageSlide = async (data) => {
    return await post(`LoginPageSlides`, data, {}, BaseUrl.Centerpoint);
  }

export const updateLoginPageSlide = async (id, data) => {
  return await put(`LoginPageSlides/${id}`, data, {}, BaseUrl.Centerpoint);
}

export const deleteLoginPageSlide = async (id) => {
    return await destroy(`LoginPageSlides/${id}`, BaseUrl.Centerpoint);
}