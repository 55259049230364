import { Fragment, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow
} from '../../../components/Table';
import { Table as TableCore } from '@metaforcelabs/metaforce-core';
import { Badge, Button } from '../../../components';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import { getCustomers } from '../../../api/approvals';
import { Form, Formik } from 'formik';
import FormikInput from '../../../components/Form/Formik/FormikInput';
import FormikRadio from '../../../components/Form/Formik/FormikRadio';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import RadioGroup from '../../../components/Form/RadioGroup';
import { Link } from 'react-router-dom';
import { useSearchQuery } from '../../../hooks/useSearchQuery';
import { useTableActions } from '@metaforcelabs/metaforce-core';
const dummyLoadData = [{}, {}, {}, {}, {}];

const activationStatus = {
  Pending: '1',
  Active: '2',
  Rejected: '3'
};

const formInitialValues = {
  searchQuery: '',
  activationStatus: activationStatus.Active
};

const Approvals = () => {
  const [formValues, setFormValues] = useState(formInitialValues);
  const [recordsToShow, setRecordsToShow] = useState(dummyLoadData);

  let { hasParam, getParam } = useSearchQuery();
  const customersAction = useToastAction();
  const tableActions = useTableActions(recordsToShow, 10);

  const fetchCustomers = (values) => {
    customersAction.execute(async () => {
      const getCustomersResult = await getCustomers({
        searchName: values ? values.searchQuery : formInitialValues.searchQuery,
        activationStatus: values ? values.activationStatus : parseInt(activationStatus.Active)
      });

      setRecordsToShow(getCustomersResult.sort((a, b) => a.name.localeCompare(b.name)));
    }, 'Failed to load customers');
  };

  const approveStatusBagde = (status) => {
    switch (status) {
      case 2:
        return <Badge type="info" text="Approved" />;
      case 3:
        return <Badge type="success" text="Unapproved" />;
      case 1:
      default:
        return <Badge type="info" text="Waiting for approvals" />;
    }
  };

  const handleOnResetFilter = (resetAction) => {
    resetAction();
    fetchCustomers();
  };

  const handleOnSubmit = async (values) => {
    await fetchCustomers(values);
  };

  useEffect(() => {
    let values = { ...formValues };

    const hasStatusParam = hasParam('status');

    if (hasStatusParam) {
      const statusParam = getParam('status');

      if (statusParam === 'pending') {
        values = { ...values, activationStatus: activationStatus.Pending };

        setFormValues(values);
      }
    }

    fetchCustomers(values);
  }, []);

  return (
    <>
      <div>
        <div className="flex flex-col pb-5 mb-10 border-b border-gray-200">
          <h1 className="text-3xl font-bold">List of approvals</h1>
          <p className="mt-2 max-w-4xl text-sm text-gray-500">
            Check current status of customer that you requested to be approved
          </p>
        </div>

        <div>
          <Formik initialValues={formValues} enableReinitialize={true} onSubmit={handleOnSubmit}>
            {(props) => (
              <Form>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-2">
                    <FormikInput label="Search" name="searchQuery" formikProps={props} />
                  </div>

                  <div className="col-span-3 sm:col-span-2">
                    <RadioGroup label="Activation status">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                        <FormikRadio
                          label="Active"
                          name="activationStatus"
                          value={activationStatus.Active}
                          formikProps={props}
                        />

                        <FormikRadio
                          label="Pending"
                          name="activationStatus"
                          value={activationStatus.Pending}
                          formikProps={props}
                        />

                        <FormikRadio
                          label="Rejected"
                          name="activationStatus"
                          value={activationStatus.Rejected}
                          formikProps={props}
                        />
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="flex items-end ml-auto col-span-3 sm:col-span-2">
                    <Button
                      variant={Button.variants.secondary}
                      onClick={() => handleOnResetFilter(props.resetForm)}
                    >
                      Reset
                    </Button>

                    <FormikSubmitButton
                      id="filter"
                      text="Filter"
                      className="ml-2 i"
                      formikProps={props}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="relative mt-8">
          <Table>
            <TableHead>
              <TableHeader>Name</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Created</TableHeader>
              <TableHeader>Updated</TableHeader>
            </TableHead>
            <TableBody>
              {tableActions.pageData?.map((row, index) => (
                <Fragment key={index}>
                  <TableRow className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                    <TableColumn>
                      <Link
                        to={`/admin/approvals/${row.customerId}`}
                        className="font-medium text-sm text-indigo-600 hover:text-indigo-500 border-0 shadow-none"
                      >
                        {row.name}
                      </Link>
                    </TableColumn>
                    <TableColumn>{approveStatusBagde(row.activationStatus)}</TableColumn>
                    <TableColumn>{stringToLocaleDateTimeString(row.createdTime)}</TableColumn>
                    <TableColumn>
                      {stringToLocaleDateTimeString(row.updatedTime || row.createdTime)}
                    </TableColumn>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
          <TableCore.Pagination tableActions={tableActions} />
        </div>
      </div>
    </>
  );
};

export default Approvals;
