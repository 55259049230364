import React from 'react';
import styles from '../DateField.module.scss';
import { useClassNames } from '../../../hooks/useClassNames';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

const DateFieldNavbar = ({ onPreviousClick, onNextClick, className }) => {
  const classNames = useClassNames();

  return (
    <div className={classNames.classNames(className, styles.navbar)}>
      <button type="button" onClick={() => onPreviousClick()}>
        <ChevronLeftIcon className="w-6 h-6 black" />
      </button>
      <button type="button" onClick={() => onNextClick()}>
        <ChevronRightIcon className="w-6 h-6 black" />
      </button>
    </div>
  );
};

export default DateFieldNavbar;
