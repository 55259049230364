import { Button } from '../../../../components';
import React, { useState } from 'react';
import FormikInput from '../../../../components/Form/Formik/FormikInput';
import { XIcon } from '@heroicons/react/outline';
import { Select } from '../../../../components/Form/Select';
import { useClassNames } from '@metaforcelabs/metaforce-core';

const Restrictions = ({ availableLimitations, planProductLimitations, formikProps, onChange }) => {
  const [selectedLimitation, setSelectedLimitation] = useState('');
  const [limitationsError, setLimitationsError] = useState(false);
  
  const { classNames } = useClassNames();

  const handleOnLimitationChange = (value) => {
    setSelectedLimitation(value);
    setLimitationsError(false);
  };

  const handleOnAddLimitation = () => {
    if (selectedLimitation) {
      formikProps.setFieldValue('limitations', {
        ...formikProps.values.limitations,
        [selectedLimitation]:
          planProductLimitations.productCategoryUsageLimitations[selectedLimitation] || ''
      });

      setLimitationsError(false);
    } else {
      setLimitationsError(true);
    }

    setSelectedLimitation('');
    onChange();
  };

  const handleOnDelete = (limitationCode) => {
    const limitations = { ...formikProps.values.limitations };
    let errors = { ...formikProps.errors };

    if (limitations.hasOwnProperty(limitationCode)) {
      delete limitations[limitationCode];

      formikProps.setFieldValue('limitations', limitations, false);
    }

    if (errors.hasOwnProperty('limitations')) {
      delete errors.limitations[limitationCode];

      errors = Object.keys(errors.limitations).length > 0 ? errors : {};

      formikProps.setErrors(errors);
    }

    onChange();
  };

  const renderLimitation = () => (
    <div className="">
      <p className="block text-sm font-medium text-gray-700">Selected limitations</p>

      <div className="pt-2 space-y-3">
        {Object.keys(formikProps.values.limitations).length === 0 ? (
          <p className="block text-sm text-gray-500">No limitation is added</p>
        ) : (
          Object.keys(formikProps.values.limitations)
          .filter(lim=> !!availableLimitations.find((el) => el.code === lim))
          .map((limitation, index) => {
            
            const availableLimitation = availableLimitations.find((el) => el.code === limitation);

            return (
              <div key={limitation} className={classNames('grid grid-cols-8 text-sm ')}>
                <div className="mt-3 col-span-4">{availableLimitation.displayName}</div>
                <div className="col-span-1">
                  <FormikInput
                    name={`limitations[${availableLimitation.code}]`}
                    formikProps={formikProps}
                    onChange={onChange}
                  />
                </div>
                <div className="mt-3 ml-3 text-gray-500 cursor-pointer hover:text-gray-800">
                  <button type="button" onClick={() => handleOnDelete(limitation)}>
                    <XIcon className="w-4 h-4" />
                  </button>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className="pt-6 items-stretch grid grid-cols-12 gap-y-6 divide-x divide-gray-300">
        <div className="col-span-5">
          <div className="items-end grid grid-cols-5 gap-4">
            <div className="col-span-3">
              <Select
                label="Available limitations"
                name="limitation"
                selectClassName={classNames(
                  limitationsError &&
                    'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500'
                )}
                selectedValue={selectedLimitation}
                options={[
                  {
                    name: 'Select',
                    value: ''
                  },
                  ...availableLimitations
                    .filter(
                      (limitation) =>
                        Object.keys(formikProps.values.limitations).indexOf(limitation.code) < 0
                    )
                    .map((limitation) => ({
                      name: limitation.displayName,
                      value: limitation.code
                    }))
                ]}
                onChange={handleOnLimitationChange}
              />
            </div>

            <div className="col-auto">
              <Button
                variant={Button.variants.secondary}
                onClick={handleOnAddLimitation}
                disabled={
                  Object.keys(formikProps.values.limitations).length === availableLimitations.length
                }
              >
                Add
              </Button>
            </div>
          </div>
        </div>

        <div className="col-span-7 pl-8">{renderLimitation()}</div>
      </div>
    </>
  );
};

export default Restrictions;
