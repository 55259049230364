import { Select } from '../../../../components/Form/Select';
import React, { useEffect, useState } from 'react';
import FormikInput from '../../../../components/Form/Formik/FormikInput';
import { MenuContextList } from '../../../../components';
import FeatureUsageThresholds from './FeatureUsageThresholds';

const Features = ({ products, formikProps, currency, onChange }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [usageThresholds, setUsageThresholds] = useState(null);
  const [selectedThresholdIndex, setSelectedThresholdIndex] = useState(0);
  const [featureIndex, setFeatureIndex] = useState(0);
  const [productsFeaturesIndex, setProductsFeaturesIndex] = useState(0);

  const productsInputOptions = [
    ...products
      .filter(
        (product) =>
          formikProps.values.products.indexOf(product.id) >= 0 && product.features.length > 0
      )
      .filter((product) =>
        product.features.some(
          (feature) => feature.isUsageFeature && feature.productFeaturePrice.pricingType === 10
        )
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((product) => ({
        name: product.name,
        value: product.id
      }))
  ];

  const handleOnUsageThresholdsChange = (value) => {
    setSelectedThresholdIndex(value);
  };

  const handleOnPriceChange = () => {
    onChange();
  };

  const handleOnEditUsageThresholds = (values, index) => {
    setUsageThresholds(JSON.parse(JSON.stringify(values)));
    setProductsFeaturesIndex(index);
  };

  const handleOnClose = () => {
    setUsageThresholds(null);
  };

  const renderFeaturePricing = (feature) => {
    const productsFeaturesIndex = formikProps.values.productsFeatures.findIndex(
      (product) => product.id === selectedProduct.id
    );

    const featureIndex = formikProps.values.productsFeatures[
      productsFeaturesIndex
    ].features.findIndex((foundFeature) => foundFeature.featureCode === feature.featureCode);

    const formikFeatureValue =
      formikProps.values.productsFeatures[productsFeaturesIndex].features[featureIndex];

    return (
      <div key={feature.featureCode} className="grid grid-cols-8 text-sm">
        <div className="mt-3 col-span-3">{feature.name}</div>

        <div className="col-span-5 grid grid-cols-8 gap-4">
          <div className="col-span-3">
            <FormikInput
              name={`productsFeatures.${productsFeaturesIndex}.features.${featureIndex}.usageThresholds.${selectedThresholdIndex}.price`}
              formikProps={formikProps}
              prefix={currency}
              onChange={handleOnPriceChange}
            />
          </div>

          <div className="col-span-4">
            <Select
              name="usageType"
              options={[
                ...formikFeatureValue.usageThresholds
                  .sort((a, b) => parseInt(a.lower) - parseInt(b.lower))
                  .map((usage, index) => ({
                    name: `${usage.lower} - ${usage.upper || 'infinity'}`,
                    value: index
                  }))
              ]}
              onChange={(value) => handleOnUsageThresholdsChange(value)}
            />
          </div>

          <div className="col-auto flex content-start">
            <MenuContextList
              container="body"
              actions={[
                {
                  id: 2,
                  name: 'Edit',
                  onClick: () =>
                    handleOnEditUsageThresholds(
                      formikFeatureValue.usageThresholds,
                      productsFeaturesIndex
                    )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleOnSave = (values) => {
    const formikValues = {
      ...JSON.parse(JSON.stringify(formikProps.values))
    };

    formikValues.productsFeatures[productsFeaturesIndex].features[featureIndex].usageThresholds =
      values;

    formikProps.setValues(formikValues, false);
    setUsageThresholds(null);
    onChange();
  };

  const handleOnProductChange = (value) => {
    const productIndex = productsInputOptions.findIndex((product) => product.value === value);
    const product = products.find((product) => product.id === value);

    if (productIndex > -1) {
      setProductsFeaturesIndex(productIndex);
      setSelectedProduct(product);
    }
  };

  useEffect(() => {
    setSelectedProduct(null);
  }, []);

  useEffect(() => {
    if (selectedProduct && formikProps.values.products.indexOf(selectedProduct.id) < 0) {
      setSelectedProduct(null);
    }
  }, [formikProps]);

  return (
    <>
      <div className="pt-6 items-stretch grid grid-cols-12 gap-y-6 divide-x divide-gray-300">
        <div className="col-span-3 pr-8">
          <Select
            label="Product"
            name="product"
            options={[
              {
                name: 'Select',
                value: ''
              },
              ...productsInputOptions
            ]}
            selectedValue={selectedProduct?.id || ''}
            onChange={handleOnProductChange}
          />
        </div>

        {selectedProduct &&
          selectedProduct.features.filter(feature => feature.isUsageFeature 
            && feature.productFeaturePrice.pricingType === 10)
            .map((feature) => (
            <div key={feature.name} className="col-span-8 pl-8">
              <div className="">
                <p className="block text-sm font-medium text-gray-700">Features</p>

                <div className="pt-2 space-y-3">{renderFeaturePricing(feature)}</div>
              </div>
            </div>
          ))}
      </div>

      {usageThresholds && (
        <FeatureUsageThresholds
          usageThresholds={usageThresholds}
          featureIndex={featureIndex}
          formikProps={formikProps}
          onClose={handleOnClose}
          onSave={handleOnSave}
        />
      )}
    </>
  );
};

export default Features;
