import React, { Fragment } from 'react';
import { Form, Formik } from 'formik';
import { Button } from '../../../../components';
import Modal from '../../../../components/Modal';
import * as Yup from 'yup';
import FormikInput from '../../../../components/Form/Formik/FormikInput';
import { FormikSubmitButton } from '../../../../components/Form/Formik/FormikSubmit';
import { createIncident } from '../../../../api/status';
import { useHistory } from 'react-router-dom';

const IncidentCreateModal = ({ open, setOpen }) => {
    const history = useHistory();

    const NewIncidentSchema = Yup.object().shape({
        title: Yup.string().required('Required'),
    });

    const handleOnSubmit = async (values) => {
        const incident = await createIncident({ title: values.title });
        history.push(`/admin/status/incident/${incident.id}`);
        setOpen(false);
    }

    return (
        <>
            <Modal
                isOpen={open}
                onClose={() => setOpen(false)}
                size={'large'}
                title="Create Incident"
            >
                <Formik
                    initialValues={{ title: '' }}
                    validationSchema={NewIncidentSchema}
                    onSubmit={handleOnSubmit}
                    enableReinitialize={true}
                >
                    {(props) => (
                        <Form>
                            <div className="mt-1 relative rounded-md w-full">
                                <FormikInput label="Title" name="title" formikProps={props} required={true} />
                            </div>

                            <div className="mt-5 flex justify-end">
                                <Button
                                    variant={Button.variants.secondary}
                                    className="mr-3"
                                    onClick={() => setOpen(false)}
                                >
                                    Cancel
                                </Button>

                                <FormikSubmitButton
                                    id="create"
                                    text="Create"
                                    className="ml-2 i"
                                    formikProps={props}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export default IncidentCreateModal;
