import React, { useState } from 'react';
import FormikRadio from '../../../../components/Form/Formik/FormikRadio';
import RadioGroup from '../../../../components/Form/RadioGroup';
import CustomerSelectionSelected from './CustomerSelection/CustomerSelectionSelected';
import CustomerSelectionList from './CustomerSelection/CustomerSelectionList';

export default function PackageCustomer({ availableCustomers, formikProps }) {

  const onCustomerAdditionToSelection = (event, customer) => {
    switch (event.detail) {
      case 2: {
        if (
          formikProps.values.sentToSpecifiedCustomersList.findIndex(
            (x) => x.customerId === customer.customerId
          ) === -1
        ) {
          let oldArray = formikProps.values.sentToSpecifiedCustomersList;
          oldArray.push(customer);
          formikProps.setFieldValue('sentToSpecifiedCustomersList', oldArray);
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const onCustomerRemovalFromSelection = (event, itemForRemoval) => {
    switch (event.detail) {
      case 2: {
        const resultArray = formikProps.values.sentToSpecifiedCustomersList.filter(
          (x) => x.customerId !== itemForRemoval
        );
        formikProps.setFieldValue('sentToSpecifiedCustomersList', resultArray);
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <>
      <div className="mt-10 ml-3 pb-10">
        <div className="mt-2">
          <RadioGroup label="Package information recipients">
            <div className="mt-3">
              <FormikRadio
                label="All customers"
                name="notificationRecipients"
                value="all"
                formikProps={formikProps}
              />
            </div>

            <div className="mt-3">
              <FormikRadio
                label="Selected Customers"
                name="notificationRecipients"
                value="selected"
                formikProps={formikProps}
              />
            </div>
          </RadioGroup>
        </div>

        {formikProps.values.notificationRecipients === 'selected' && (
          <div className="grid grid-cols-5 mt-10 mr-10">
            <div className="col-span-2">
              <CustomerSelectionList
                customersList={availableCustomers}
                selectedCustomers={formikProps.values.sentToSpecifiedCustomersList}
                onAddCustomer={onCustomerAdditionToSelection}
              />
            </div>
            <div></div>
            <div className="col-span-2">
              <CustomerSelectionSelected
                selectedCustomers={formikProps.values.sentToSpecifiedCustomersList}
                onRemoveCustomer={onCustomerRemovalFromSelection}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
