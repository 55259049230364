import { Select } from '../../../../components/Form/Select';
import React, { useEffect, useState } from 'react';
import FormikInput from '../../../../components/Form/Formik/FormikInput';
import { Button, MenuContextList } from '../../../../components';
import FeatureUsageThresholds from './FeatureUsageThresholds';
import { useClassNames } from '@metaforcelabs/metaforce-core';
import { getByCurrency } from '../services';

const GlobalFeatures = ({ formikProps, selectedPackage, currency, onChange }) => {
  const [usageThresholds, setUsageThresholds] = useState(null);
  const [selectedThresholdIndex, setSelectedThresholdIndex] = useState(0);
  const [featureIndex, setFeatureIndex] = useState(0);
  const [errors, setErrors] = useState(false);
  const [selectedGlobalFeatureCode, setSelectedGlobalFeatureCode] = useState('');
  const [options, setOptions] = useState([]);

  const { classNames } = useClassNames();

  const generateOptions = () => [
    ...selectedPackage.globalFeatures
      .filter((globalFeature) => {
        return (
          formikProps.values.globalFeatures.length === 0 ||
          formikProps.values.globalFeatures.some((el) => {
            return el.featureCode !== globalFeature.featureCode;
          })
        );
      })
      .map((globalFeature) => ({
        name: globalFeature.name,
        value: globalFeature.featureCode
      }))
  ];

  const handleOnUsageTypeChange = (value) => {
    setSelectedThresholdIndex(value);
    setFeatureIndex(value);
  };

  const handleOnPriceChange = () => {
    onChange();
  };

  const handleOnEditUsageThresholds = (values, index) => {
    setUsageThresholds(JSON.parse(JSON.stringify(values)));
    setFeatureIndex(index);
  };

  const handleOnClose = () => {
    setUsageThresholds(null);
  };

  const handleOnSave = (values) => {
    const formikValues = {
      ...JSON.parse(JSON.stringify(formikProps.values))
    };

    formikValues.globalFeatures[featureIndex].usageThresholds = values;
    formikProps.setValues(formikValues, false);
    setUsageThresholds(null);
    onChange();
  };

  const handleOnFeatureChange = (value) => {
    setSelectedGlobalFeatureCode(value);
    setErrors(false);
  };

  const handleOnAddFeature = () => {
    if (selectedGlobalFeatureCode) {
      const globalFeatureData = selectedPackage.globalFeatures.find(
        (el) => el.featureCode === selectedGlobalFeatureCode
      );

      formikProps.setFieldValue('globalFeatures', [
        ...formikProps.values.globalFeatures,
        {
          name: globalFeatureData.name,
          featureCode: globalFeatureData.featureCode,
          usageThresholds: globalFeatureData.productFeaturePrice.usageThresholds.map(
            (usageThreshold) => ({
              lower: usageThreshold.lower,
              upper: usageThreshold.upper,
              price: getByCurrency(
                usageThreshold.usageCategories[0].usagePrices,
                currency,
                'usagePriceCurrency',
                'usagePrice'
              ).toString()
            })
          )
        }
      ]);

      setErrors(false);
    } else {
      setErrors(true);
    }

    setSelectedGlobalFeatureCode('');
    onChange();
  };

  const renderGlobalFeatures = () => {
    return (
      <div>
        <p className="block text-sm font-medium text-gray-700">Selected global features</p>

        <div className="pt-2 space-y-3">
          {formikProps.values.globalFeatures.length === 0 ? (
            <p className="block text-sm text-gray-500">No global features are added</p>
          ) : (
            <>
              {formikProps.values.globalFeatures.map((globalFeature, index) => (
                <div key={globalFeature.featureCode} className="grid grid-cols-8 text-sm">
                  <div className="col-span-8">{globalFeature.name}</div>

                  <div className="mt-3 col-span-12 grid grid-cols-8 gap-4">
                    <div className="col-span-2">
                      <FormikInput
                        name={`globalFeatures.${index}.usageThresholds.${selectedThresholdIndex}.price`}
                        formikProps={formikProps}
                        prefix={currency}
                        onChange={handleOnPriceChange}
                      />
                    </div>

                    <div className="col-span-4">
                      <Select
                        name="usageType"
                        options={[
                          ...globalFeature.usageThresholds
                            .sort((a, b) => parseInt(a.lower) - parseInt(b.lower))
                            .map((usage, index) => ({
                              name: `${usage.lower} - ${usage.upper || 'infinity'}`,
                              value: index
                            }))
                        ]}
                        onChange={(value) => handleOnUsageTypeChange(value)}
                      />
                    </div>

                    <div className="col-auto flex content-start">
                      <MenuContextList
                        container="body"
                        actions={[
                          {
                            id: 2,
                            name: 'Edit',
                            onClick: () =>
                              handleOnEditUsageThresholds(globalFeature.usageThresholds, index)
                          }
                        ]}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    setErrors(false);
    setOptions(() => generateOptions());
  }, [selectedPackage, formikProps.values.globalFeatures]);

  return (
    <>
      <div className="pt-6 items-stretch grid grid-cols-12 gap-y-6 divide-x divide-gray-300">
        <div className="col-span-5">
          <div className="items-end grid grid-cols-5 gap-4">
            <div className="col-span-3">
              <Select
                label="Available global features"
                name="limitation"
                selectClassName={classNames(
                  errors && 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500'
                )}
                selectedValue={selectedGlobalFeatureCode}
                options={[
                  {
                    name: 'Select',
                    value: ''
                  },
                  ...options
                ]}
                onChange={handleOnFeatureChange}
              />
            </div>

            <div className="col-auto">
              <Button
                variant={Button.variants.secondary}
                onClick={handleOnAddFeature}
                disabled={Object.keys(formikProps.values.globalFeatures).length === options.length}
              >
                Add
              </Button>
            </div>
          </div>
        </div>

        <div className="col-span-7 pl-8">{renderGlobalFeatures()}</div>
      </div>

      {usageThresholds && (
        <FeatureUsageThresholds
          usageThresholds={usageThresholds}
          onClose={handleOnClose}
          onSave={handleOnSave}
        />
      )}
    </>
  );
};

export default GlobalFeatures;
