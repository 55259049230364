import { useContext, useRef, useState } from 'react'
import { LoginPageSlideElementContext } from '../../contexts';
import useClickOutside from "../../hooks/useClickOutside";
import { useToastAction } from '@metaforcelabs/metaforce-core';
import { TrashIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/solid';

const loginPageSlideState = {
  Draft: 0,
  Published: 1
};

export default function LoginPageSlideElementMenuContext({open}) {
  const menu = useRef();
  const {
      showRightClickMenu,
      setShowRightClickMenu,
      rightClickMenuPosition,
      setRightClickLoginPageSlideData,
      rightClickLoginPageSlideData,
      toggleLoginPageSlideState,
      confirmDeleteLoginPageSlide
  } = useContext(LoginPageSlideElementContext) || {};
  const [showRemoveMenu, setShowRemoveMenu] = useState(false);
  const loginPageSlideElementContext = useContext(LoginPageSlideElementContext);
  const updateLoginPageSlideAction = useToastAction();

  useClickOutside(menu, () => setShowRightClickMenu(false));

  const close = () => {
    setShowRightClickMenu(false);
    setShowRemoveMenu(false);
  }

  const handleToggleLoginPageSlideState = () => {
    toggleLoginPageSlideState();
    close();
  }

  const handleRemoveLoginPageSlide = () => {
    confirmDeleteLoginPageSlide.open();
    close();
  }

  const getRightClickMenuPositionY = () => {
    return rightClickMenuPosition?.y || 0;
  }
  
  const getRightClickMenuPositionX = () => {
    return rightClickMenuPosition?.x || 0
  }

  return (
    <>
      {
        showRightClickMenu &&      
        <div
          ref={menu} 
          static className="absolute z-50 border border-gray-200 divide-y divide-gray-100 rounded-md bg-white shadow-lg"
          style={{top: `${getRightClickMenuPositionY()}px`, left: `${getRightClickMenuPositionX()}px`}}
        >
          <div className="flex flex-col w-56  py-1">
            <div className='flex justify-between hover:bg-gray-100 hover:text-gray-900'>
              <button
                onClick={handleToggleLoginPageSlideState}
                className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
              >
              {
                rightClickLoginPageSlideData.state === loginPageSlideState.Draft ?   
                <EyeIcon
                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                :
                <EyeOffIcon
                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              }
                {rightClickLoginPageSlideData.state === loginPageSlideState.Draft ? 'Publish' : 'Unpublish'}
              </button>
            </div>
            <div className='flex justify-between hover:bg-gray-100 hover:text-gray-900'>             
              <button
                onClick={handleRemoveLoginPageSlide}
                className={'group flex items-center px-5 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'}
              >
                <TrashIcon
                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Remove
              </button>     
            </div>

          </div>
          
        </div>
      }
    </>
  )
}