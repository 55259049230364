import React, { useEffect, useState } from 'react';
import {
  CoreTable,
  MenuBarDesktopLink,
  PageHeader,
  translateConstantsToReadableNames,
  CustomerUserStatusDictionary,
  PredefinedUserRoleIdDictionary,
  useToastAction
} from '@metaforcelabs/metaforce-core';
import { getCustomer, getCustomerAdminUsers } from '../../../api/approvals';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { SearchIcon } from '@heroicons/react/outline';

const CustomerAdminContactList = ({ match }) => {
  const [customerName, setCustomerName] = useState('');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const customerAction = useToastAction();
  const usersListAction = useToastAction();

  const contactsColumns = [
    {
      name: 'Name',
      idName: 'name',
      headerClassNames: 'font-bold',
      rowClassNames: 'font-medium',
      orderNumber: 1
    },
    {
      name: 'E-mail',
      idName: 'email',
      orderNumber: 1
    },
    {
      name: 'Status',
      idName: 'customerUserStatus',
      orderNumber: 1
    },
    {
      name: 'Role',
      idName: 'roleId',
      orderNumber: 1
    }
  ];

  const fetchCustomer = () => {
    customerAction.execute(async () => {
      const getCustomersResult = await getCustomer(match.params.customerId);
      setCustomerName(getCustomersResult.name ? getCustomersResult.name : '');
    }, 'Failed to load customer data');
  };

  const fetchUsersList = () => {
    usersListAction.execute(async () => {
      let getUsersResult = await getCustomerAdminUsers(match.params.customerId);
      getUsersResult = translateConstantsToReadableNames(
        getUsersResult,
        'customerUserStatus',
        CustomerUserStatusDictionary
      );
      getUsersResult = translateConstantsToReadableNames(
        getUsersResult,
        'roleId',
        PredefinedUserRoleIdDictionary
      );
      setUsers(getUsersResult);
      setFilteredUsers(getUsersResult);
    }, 'Failed to load admin users contact data');
  };

  const handleSearch = (e) => {
    const searchBy = e.target.value;
    let filterResult = users.filter((x) => {
      return namePredicate(x, searchBy) || emailPredicate(x, searchBy);
    });
    setFilteredUsers(filterResult);
  };

  const namePredicate = (user, name) => {
    return !name || user.name.toLowerCase().includes(name.toLowerCase());
  };

  const emailPredicate = (user, email) => {
    return !email || user.email.toLowerCase().includes(email.toLowerCase());
  };

  useEffect(() => {
    fetchCustomer();
    fetchUsersList();
  }, []);

  return (
    <>
      <div className="space-y-8">
        <PageHeader title={'Company - ' + customerName} />
        <div className="flex gap-10">
          <MenuBarDesktopLink isActive={false}>
            <Link
              to={'/admin/approvals/' + match.params.customerId + '/settings'}
              className="font-medium text-sm border-0 shadow-none"
            >
              Settings
            </Link>
          </MenuBarDesktopLink>
          <MenuBarDesktopLink isActive={true}>
            <Link
              to={'/admin/approvals/' + match.params.customerId + '/contacts'}
              className="font-medium text-sm border-0 shadow-none"
            >
              Contacts
            </Link>
          </MenuBarDesktopLink>
        </div>
        <div className="bg-white py-6 px-4 sm:p-6 shadow sm:rounded-md sm:overflow-hidden">
          <div className="xl:w-5/6">
            <section className="md:w-1/3 md:ml-auto md:mr-8">
              <div className="my-5">
                <label htmlFor="desktop-search-candidate" className="sr-only">
                  Search
                </label>
                <div className="flex rounded-md shadow-sm">
                  <div className="relative flex-grow focus-within:z-10">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                      type="text"
                      name="desktop-search-candidate"
                      id="desktop-search-candidate"
                      className="focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 block sm:text-sm border-gray-300"
                      placeholder="Search"
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="w-full pb-10 mb-10">
                <CoreTable columns={contactsColumns} data={filteredUsers} maxHeight="30rem" />
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerAdminContactList;
