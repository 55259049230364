import { post, get } from './api';

export const getCustomersApi = async () => {
  return await get(`License/GetCustomers`);
};

export const getAvailableProductsApi = async () => {
  return await get(`License/GetAvailableProducts`);
};

export const getPackagesApi = async () => {
  return await get(`License/GetPackages`);
};

export const getCustomerLicenseApi = async (customerId) => {
  return await get(`License/GetCustomerLicense/${customerId}`);
};

export const updateCustomerLicenseApi = async (customerId, data) => {
  return await post(`License/UpdateCustomerLicense/${customerId}`, data);
};

export const getAvailableFeatureLimitations = async () => {
  return await get(`License/getAvailableFeatureLimitations`);
};
