export const Checkbox = ({
  name,
  label,
  checked,
  onChange,
  inputClassName = null,
  labelClassName = null,
  disabled = false
}) => {
  return (
    <div className="flex items-center ">
      <input
        type={'checkbox'}
        name={name}
        id={name}
        disabled={disabled}
        checked={checked}
        onChange={(evt) => onChange(evt.target.checked, name)}
        className={`mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded ${inputClassName}`}
      />
      <label className={`text-sm font-medium text-gray-700 ${labelClassName}`} htmlFor={name}>
        {label}
      </label>
    </div>
  );
};
