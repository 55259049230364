import { Field } from 'formik';
import FormikErrorMessage from './FormikErrorMessage';
import { useCallback, useState } from 'react';
import { useClassNames } from '@metaforcelabs/metaforce-core';

const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500';

export default function FormikDate({
  inline,
  name,
  label,
  onChange,
  isAutoCompleteDisabled,
  isAutoCompleteOnlyOnFocus,
  placeholder = null,
  required = false,
  prefix,
  disabled
}) {
  const { classNames } = useClassNames();

  const [autocomplete, setAutocomplete] = useState(() =>
    isAutoCompleteDisabled || isAutoCompleteOnlyOnFocus ? 'new-password' : ''
  );

  const handleOnFocus = useCallback(() => {
    if (isAutoCompleteOnlyOnFocus) {
      setAutocomplete('');
    }
  }, [isAutoCompleteOnlyOnFocus]);

  const handleOnBlur = useCallback(
    (e) => {
      if (isAutoCompleteOnlyOnFocus) {
        setAutocomplete('new-password');
      }
    },
    [isAutoCompleteOnlyOnFocus]
  );

  return (
    <div className={classNames(inline && 'flex items-center')}>
      {label && (
        <label
          htmlFor={name}
          className={classNames('block text-sm font-medium text-gray-700', inline && 'mr-3')}
        >
          {label}
          {required === true && <span className="text-red-400"> *</span>}
        </label>
      )}

      <Field name={name}>
        {({ field, meta }) => (
          <div className={classNames('relative', label && 'mt-1')}>
            <input
              type="date"
              className={classNames(
                'shadow-sm block w-full h-10 sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400',
                meta.touched && meta.error ? errorClasses : '',
                disabled && 'cursor-not-allowed border-gray-200 bg-gray-50 text-gray-500 '
              )}
              placeholder={placeholder}
              {...field}
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
              onFocus={handleOnFocus}
              onBlur={(e) => {
                field.onBlur(e);

                if (handleOnBlur) {
                  handleOnBlur(e);
                }
              }}
              autoComplete={autocomplete}
              disabled={disabled}
            />

            {prefix && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  {prefix}
                </span>
              </div>
            )}

            <FormikErrorMessage name={name} />
          </div>
        )}
      </Field>
    </div>
  );
}
