import { post, get } from './api';

export const getCustomers = async (data) => {
  return await post(`Approvals/GetCustomers`, data);
};

export const getCustomerAdminUsers = async (customerId) => {
  return await post(`Approvals/GetCustomerAdminUsers/${customerId}`);
};

export const saveCustomer = async (data) => {
  return await post(`Approvals/ChangeCustomerApprovalStatus`, data);
};

export const getCustomer = async (customerId) => {
  return await get(`Approvals/GetCustomer/${customerId}`);
};

export const getCountries = async () => {
  return await get(`Approvals/GetCountries`);
};

export const getExternalProviderCustomerData = async (customerId) => {
  return await get(`Approvals/GetCustomerExternalDataRaw/${customerId}`);
};

export const getBrregFulfill = async (customerId) => {
  return await get(`Approvals/GetCustomerExternalData/${customerId}`);
};
