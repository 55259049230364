import { Button, Modal } from '../../../../components';
import React, { useEffect, useState } from 'react';
import { useClassNames } from '../../../../hooks/useClassNames';
import { XIcon, PlusSmIcon, ExclamationIcon } from '@heroicons/react/outline';
import { TextInput } from '../../../../components/Form/TextInput';

const FeatureUsageThresholds = ({ usageThresholds, onClose, onSave }) => {
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [error, setError] = useState('');

  const { classNames } = useClassNames();

  const validationRanges = () => {
    let isValid = true;
    const sortedValues = [...formValues].sort((a, b) => parseInt(a.lower) - parseInt(b.lower));

    for (let i = 0; i < sortedValues.length; i++) {
      if (sortedValues[i].price === '') {
        isValid = false;
        break;
      }

      if (
        sortedValues[i + 1] &&
        parseInt(sortedValues[i + 1].lower) - 1 !== parseInt(sortedValues[i].upper)
      ) {
        isValid = false;
        break;
      } else if (sortedValues.length === i + 1) {
        if (sortedValues.length === 1 && parseInt(sortedValues[i].lower) !== 1) {
          isValid = false;
          break;
        } else if (sortedValues[i].upper !== null && sortedValues[i].upper !== '') {
          isValid = false;
          break;
        }
      }
    }

    return isValid;
  };

  const handleOnSave = () => {
    const isValid = validationRanges();

    if (isValid) {
      setError('');
      onSave(formValues);
    } else {
      setError('You need to cover all threshold ranges from 1 to infinity, and set all prices.');
    }
  };

  const handleOnClose = () => {
    onClose();
    setError('');
  };

  const handleOnAddClick = () => {
    setFormValues((state) => [
      ...state,
      {
        price: '',
        lower: '',
        upper: ''
      }
    ]);
  };

  const handleOnDeleteClick = (index) => {
    setFormValues((state) => {
      const newState = [...state];
      newState.splice(index, 1);

      return newState;
    });
  };

  const handleOnInputChange = (value, name, index) => {
    setFormValues((state) => {
      let newState = [...state];

      newState[index][name] = value;

      return newState;
    });
  };

  useEffect(() => {
    if (usageThresholds) {
      setShowModal(true);
      setFormValues(usageThresholds.sort((a, b) => parseInt(a.lower) - parseInt(b.lower)));
    } else {
      setShowModal(false);
    }
  }, [usageThresholds]);

  return (
    <Modal
      isOpen={showModal}
      size="medium"
      onClose={() => setShowModal(false)}
      title="Edit usage thresholds"
    >
      <div className="overflow-y-auto">
        <ul>
          <li className="grid grid-cols-8 gap-x-4">
            <div className="col-auto"></div>
            <div className="col-span-2">Price</div>
            <div className="col-span-2">Lower</div>
            <div className="col-span-2">Upper</div>
            <div className="col-span-2"></div>
          </li>

          <>
            {formValues.map((value, index) => (
              <li
                key={index}
                className={classNames('grid grid-cols-8 gap-4 py-4', index % 2 && 'bg-gray-50')}
              >
                <div className="col-auto">{index + 1}</div>

                <div className="col-span-2">
                  <TextInput
                    name="price"
                    value={value.price}
                    onChange={(value, name) => handleOnInputChange(value, name, index)}
                  />
                </div>

                <div className="col-span-2">
                  <TextInput
                    name="lower"
                    value={value.lower}
                    onChange={(value, name) => handleOnInputChange(value, name, index)}
                  />
                </div>

                <div className="col-span-2">
                  <TextInput
                    name="upper"
                    value={value.upper}
                    onChange={(value, name) => handleOnInputChange(value, name, index)}
                  />
                </div>

                <div className="col-auto flex justify-center items-center ">
                  <div className="text-gray-500 cursor-pointer hover:text-gray-800">
                    <button type="button" onClick={() => handleOnDeleteClick(index)}>
                      <XIcon className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </li>
            ))}

            {error && (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="w-4 h-4 text-red-700" />
                  </div>
                  <div className="ml-3 text-sm text-red-700">{error}</div>
                </div>
              </div>
            )}

            <div className="mt-3">
              <Button variant={Button.variants.secondary} onClick={handleOnAddClick}>
                Add
                <PlusSmIcon className="ml-3 w-4 h-4" />
              </Button>
            </div>
          </>
        </ul>
      </div>

      <div className="flex justify-end mt-5 sm:mt-6">
        <Button variant={Button.variants.secondary} className="mr-3" onClick={handleOnClose}>
          Cancel
        </Button>

        <Button variant={Button.variants.primary} onClick={() => handleOnSave()}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default FeatureUsageThresholds;
