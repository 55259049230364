import React, { useEffect, useState } from 'react';
import { PageHeader, useToastAction } from '@metaforcelabs/metaforce-core';
import { getLoginPageSlide, createLoginPageSlide, updateLoginPageSlide, deleteLoginPageSlide} from '../../../api/loginPageSlides';
import { Form, Formik } from 'formik';
import FormikInput from '../../../components/Form/Formik/FormikInput';
import { Button } from '../../../components';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { useHistory } from 'react-router-dom';
import FormikTextArea from '../../../components/Form/Formik/FormikTextArea';
import FormikRadio from '../../../components/Form/Formik/FormikRadio';
import RadioGroup from '../../../components/Form/RadioGroup';
import Confirm from "../../../components/Confirm";
import { useModalHelper } from '../../../hooks/useModalHelper';

const formInitialValues = {};

const loginPageSlideState = {
  Draft: 0,
  Published: 1
};

const LoginPageSlide = ({ match }) => {
  const [loginPageSlide, setLoginPageSlide] = useState(null);
  const [formValues, setFormValues] = useState(formInitialValues);
  
  let history = useHistory();
  const loginPageSlideAction = useToastAction();
  const saveLoginPageSlideAction = useToastAction();
  const confirmDeleteLoginPageSlide = useModalHelper();

  const fetchLoginPageSlide = () => {
    loginPageSlideAction.execute(async () => {
      const loginPageSlideId = match.params.loginPageSlideId;
      let getLoginPageSlideResult;
      if (loginPageSlideId === 'create') {
        getLoginPageSlideResult = {
          title: 'New login page slide',
          content: '',
          state: loginPageSlideState.Draft,
          priority: 0  
        }
      }
      else {
        getLoginPageSlideResult = await getLoginPageSlide(match.params.loginPageSlideId);
      }
      setLoginPageSlide(getLoginPageSlideResult);
      setFormValues({
        title: getLoginPageSlideResult.title,
        content: getLoginPageSlideResult.content,
        state: getLoginPageSlideResult.state,
        priority: getLoginPageSlideResult.priority.toString()
      });
    }, 'Failed to load data');
  };

  const handleOnSubmit = (values, actions) => {
    saveLoginPageSlideAction.execute(
      async () => {
        if (loginPageSlide.id) {
          await updateLoginPageSlide(loginPageSlide.id, {
            title: values.title,
            content: values.content,
            state: values.state,
            priority: parseInt(values.priority)
          });
        }
        else {
          await createLoginPageSlide({
            title: values.title,
            content: values.content,
            state: values.state,
            priority: values.priority
          });
        }
        actions.setSubmitting(false);
        history.push('/admin/loginpageslides');
      },
      'Failed to save data',
      'Save successful'
    );
  };

  const handleOnDelete = () => {
    confirmDeleteLoginPageSlide.open();
  };

  const handleDeleteLoginPageSlide = async () => {
    if (loginPageSlide.id) {
      await deleteLoginPageSlide(loginPageSlide.id);
    }
    history.push('/admin/loginpageslides');
  }

  const handleOnCancel = () => {
    history.push('/admin/loginpageslides');
  };

  useEffect(() => {
    fetchLoginPageSlide();
  }, []);

  return (
    <>
      <div className="space-y-8">
        <PageHeader title={'Login page slide - ' + formValues.title} />

        {loginPageSlide && (
          <div className="bg-white py-6 px-4 sm:p-6 shadow sm:rounded-md sm:overflow-hidden">
            <Formik initialValues={formValues} onSubmit={handleOnSubmit} enableReinitialize={true}>
              {(props) => (
                <Form>
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="md:grid md:grid-cols-4 md:gap-6">
                      <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Logn page slide</h3>
                      </div>

                      <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
                        <FormikInput label="Title" name="title" formikProps={props} />
                        <FormikTextArea label="HTML content" name="content" formikProps={props} />
                        <div style={{width: '80px'}}>
                          <FormikInput label="Priority" name="priority" formikProps={props} />
                        </div>
                        <RadioGroup label="Publication status">
                          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <FormikRadio
                              label="Published"
                              name="state"
                              value={loginPageSlideState.Published}
                              formikProps={props}
                            />
                            
                            <FormikRadio
                              label="Draft"
                              name="state"
                              value={loginPageSlideState.Draft}
                              formikProps={props}
                            />
                          </div>
                        </RadioGroup>
                      </div> 
                    </div>

                    <div className="pt-5">
                      <div className="flex justify-between">
                        <div className="inline-block">
                          {loginPageSlide.id && (
                              <Button
                                variant={Button.variants.danger}
                                onClick={handleOnDelete}
                              >
                                Delete
                              </Button>
                          )}
                        </div>
                        <div className="inline-block">
                          <Button
                            variant={Button.variants.secondary}
                            onClick={handleOnCancel}
                          >
                            Cancel
                          </Button>

                          <FormikSubmitButton
                            id="save"
                            text="Save"
                            className="ml-2 i"
                            formikProps={props}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <Confirm
              modalHelper={confirmDeleteLoginPageSlide}
              title={`Are you sure you want to delete this login page slide?`}
              onConfirm={handleDeleteLoginPageSlide}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default LoginPageSlide;
