import { Fragment, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow
} from '../../../components/Table';
import { Table as TableCore } from '@metaforcelabs/metaforce-core';
import { Button } from '../../../components';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import { Form, Formik } from 'formik';
import FormikInput from '../../../components/Form/Formik/FormikInput';
import FormikDate from '../../../components/Form/Formik/FormikDate';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { Link } from 'react-router-dom';
import { useTableActions } from '@metaforcelabs/metaforce-core';
import { searchIncidents } from '../../../api/status';
import DateTimeParser from '../../../utils/DateTimeParser';
import IncidentCreateModal from './components/IncidentCreateModal';

const dayBefore30 = () => {
  let date = new Date();
  date.setDate(date.getDate() - 29);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
}

const formInitialValues = {
  text: '',
  startTime: dayBefore30().toISOString().split('T')[0],
  endTime: (new Date()).toISOString().split('T')[0],
};

const Status = () => {
  const [recordsToShow, setRecordsToShow] = useState([]);
  const [incidentCreateModalOpen, setIncidentCreateModalOpen] = useState(false);

  const incidentsAction = useToastAction();
  const tableActions = useTableActions(recordsToShow, 10);

  const fetchIncidents = (values) => {
    incidentsAction.execute(async () => {
      const startTime = DateTimeParser
        .setSOD(values ? values.startTime : formInitialValues.startTime);
      const endTime = DateTimeParser.setEOD(values ? values.endTime : formInitialValues.endTime);

      const incidentsResult = await searchIncidents({
        text: values ? values.text : formInitialValues.text,
        startTime: startTime,
        endTime: endTime,
      });

      setRecordsToShow(incidentsResult);
    }, 'Failed to load incidents');
  };

  const handleOnSubmit = async (values) => {
    await fetchIncidents(values);
  };

  useEffect(() => {
    fetchIncidents();
  }, []);

  return (
    <>
      <div>
        <div className="flex flex-col pb-5 mb-10 border-b border-gray-200">
          <h1 className="text-3xl font-bold">List of incidents</h1>
          <p className="mt-2 max-w-4xl text-sm text-gray-500">
            Manage status incidents
          </p>
        </div>

        <div>
          <Formik
            initialValues={formInitialValues}
            enableReinitialize={true}
            onSubmit={handleOnSubmit}
          >
            {(props) => (
              <Form>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-2">
                    <FormikInput label="Search" name="text" formikProps={props} />
                  </div>

                  <div className="col-span-6 sm:col-span-1">
                    <FormikDate label="From" name="startTime" value={formInitialValues.startTime} formikProps={props} />
                  </div>

                  <div className="col-span-6 sm:col-span-1">
                    <FormikDate label="To" name="endTime" formikProps={props} />
                  </div>

                  <div className="flex items-end ml-auto col-span-3 sm:col-span-2">
                    <FormikSubmitButton
                      id="filter"
                      text="Filter"
                      className="mr-2 i"
                      formikProps={props}
                    />

                    <Button
                      variant={Button.variants.secondary}
                      onClick={() => setIncidentCreateModalOpen(true)}
                    >
                      New
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="relative mt-8">
          <Table>
            <TableHead>
              <TableHeader>Name</TableHeader>
              <TableHeader>Created</TableHeader>
              <TableHeader>Updated</TableHeader>
            </TableHead>
            <TableBody>
              {tableActions.pageData?.map((row, index) => (
                <Fragment key={index}>
                  <TableRow className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                    <TableColumn
                      style={{
                        maxWidth: '300px',
                        whiteSpace: 'normal',
                      }}
                    >
                      <Link
                        to={`/admin/status/incident/${row.id}`}
                        className="font-medium text-sm text-indigo-600 hover:text-indigo-500 border-0 shadow-none"
                      >
                        {row.title}
                      </Link>
                    </TableColumn>
                    <TableColumn>{stringToLocaleDateTimeString(row.createdDate)}</TableColumn>
                    <TableColumn>{stringToLocaleDateTimeString(row.updatedDate)}</TableColumn>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
          <TableCore.Pagination tableActions={tableActions} />
        </div>
      </div>
      <IncidentCreateModal open={incidentCreateModalOpen} setOpen={setIncidentCreateModalOpen} />
    </>
  );
};

export default Status;
