import { Field } from 'formik';
import { useClassNames } from '@metaforcelabs/metaforce-core';

const FormikRadio = ({
  name,
  value,
  label,
  description,
  errorClasses,
  formikProps,
  onChange,
  required
}) => {
  const hasError = formikProps?.errors && formikProps.errors[name];
  const { classNames } = useClassNames();

  return (
    <div
      className={classNames('flex cursor-pointer', description ? 'items-start' : 'items-center')}
    >
      <div className="flex h-5 items-center">
        <Field name={name}>
          {({ field }) => (
            <input
              type="radio"
              id={value}
              {...field}
              value={value}
              className={`h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 ${
                hasError ? errorClasses : ''
              }`}
              checked={field.value === value}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
            />
          )}
        </Field>
      </div>

      <div className="ml-3">
        <label htmlFor={value} className="block cursor-pointer">
          <span className="text-sm font-medium text-gray-700 ">{label}</span>

          {description && (
            <span id={`${value}-description`} className="block text-sm text-gray-500">
              {description}
            </span>
          )}
        </label>
      </div>
    </div>
  );
};

export default FormikRadio;
