export const NotFound = () => {
  return (

    <main className="min-h-screen h-full flex items-center px-6 lg:px-32 bg-purple-900 text-white relative">
      <header className="w-full absolute left-0 top-0 p-6 lg:p-32">
      </header>
      <section className="w-full md:w-9/12 xl:w-8/12">
        <span className="font-bold uppercase tracking-widest">404</span>
        <h1 className="text-3xl lg:text-5xl font-bold text-pink-500">
          Not<br />Found
    </h1>
        {/* <p className="font-bold mb-1">The Design is in the des...</p>
        <p>Lorem ipsum dolor sit amet...</p> */}
      </section>
      {/* <footer className="absolute right-0 bottom-0 p-6 lg:p-32">
        <p className="font-bold mb-1">Yours Truly</p>
        <p>Chigozie Orunta (Full Stack Engineer)</p>
      </footer> */}
    </main>
  )
}