import { Field } from 'formik';
import FormikErrorMessage from './FormikErrorMessage';
import { useClassNames } from '@metaforcelabs/metaforce-core';

const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500';

export default function FormikTextArea({
  inline,
  name,
  label,
  onChange,
  defaultValue,
  maxLength,
  rows,
  placeholder = null,
  required = false,
  disabled
}) {
  const { classNames } = useClassNames();

  return (
    <div className={classNames(inline && 'flex items-center')}>
      {label && (
        <label
          htmlFor={name}
          className={classNames('block text-sm font-medium text-gray-700', inline && 'mr-3')}
        >
          {label}
          {required === true && <span className="text-red-400"> *</span>}
        </label>
      )}

      <Field name={name}>
        {({ field, meta }) => (
          <div className={classNames('relative', label && 'mt-1')}>
            <textarea
              name={name}
              rows={rows}
              value={defaultValue || field.value || ''}
              maxLength={maxLength}
              placeholder={placeholder}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
              onBlur={(e) => {
                field.onBlur(e);
              }}
              className={classNames(
                'shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400',
                meta.touched && meta.error ? errorClasses : '',
                disabled && 'cursor-not-allowed border-gray-200 bg-gray-50 text-gray-500 '
              )}
            ></textarea>
            <FormikErrorMessage name={name} />
          </div>
        )}
      </Field>
    </div>
  );
}
