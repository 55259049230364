export const initTabs = [
  {
    name: 'Settings',
    current: true
  },
  {
    name: 'JSON',
    current: false
  }
];

export const subscriptionModel = {
  Monthly: 0,
  MonthlyPerUser: 1
};

export const initFormValues = {
  licenseType: subscriptionModel.Monthly.toString(),
  tierlevel:'',
  planName: '',
  plansPrices: [],
  products: [],
  globalFeatures: [],
  features: {},
  plan: {
    price: '',
    tierLevel: '',
    requirePaymentRegistration: '',
    prePriced: true
  },
  maxUsers: '1',
  limitations: []
};