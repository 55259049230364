import { TextInput } from '../../../../components/Form/TextInput';
import React, { useEffect, useState } from 'react';
import { useClassNames } from '../../../../hooks/useClassNames';
import styles from './CustomerList.module.scss';

const CustomerList = ({ customers, onSelectCustomer }) => {
  const [searchCustomerValue, setSearchCustomerValue] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const { classNames } = useClassNames();

  const handleOnItemListClick = (customer) => {
    setSelectedCustomer(customer);
    onSelectCustomer(customer);
  };

  useEffect(() => {
    setSelectedCustomer(customers.sort((a, b) => a.name.localeCompare(b.name))[0]);
  }, []);

  return (
    <>
      <div className="px-5">
        <p className="text-lg font-medium leading-6 text-gray-900">List of customers</p>
      </div>

      <div className="mt-5 px-5">
        <TextInput
          name="searchCustomer"
          placeholder="Search customer..."
          value={searchCustomerValue}
          onChange={(value) => setSearchCustomerValue(value)}
        />
      </div>

      <div className={classNames(customers.length > 10 && 'overflow-y-auto')}>
        <ul
          className={classNames('mt-4 px-5 divide-y divide-gray-200 overflow-y-auto', styles.list)}
        >
          {customers
            .filter((c) => {
              return searchCustomerValue.length
                ? c.name.toLowerCase().includes(searchCustomerValue.toLowerCase())
                : true;
            })
            .filter((c, index) => (index > 50 ? false : true))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((c, index) => (
              <li
                key={c.customerId}
                className={classNames(
                  'px-4 py-4 cursor-pointer hover:bg-gray-100 hover:text-gray-900',
                  index % 2 && 'bg-gray-50',
                  selectedCustomer &&
                    selectedCustomer.customerId === c.customerId &&
                    'bg-indigo-100'
                )}
                onClick={() => handleOnItemListClick(c)}
              >
                <div className="flex items-center space-x-4">
                  <p className="truncate text-sm font-medium text-gray-900">{c.name}</p>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default CustomerList;
