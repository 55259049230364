import React, { useEffect, useState } from 'react';
import { MenuBarDesktopLink, PageHeader, useToastAction } from '@metaforcelabs/metaforce-core';
import {
  getBrregFulfill,
  getCountries,
  getCustomer,
  getExternalProviderCustomerData,
  saveCustomer
} from '../../../api/approvals';
import { Form, Formik } from 'formik';
import FormikInput from '../../../components/Form/Formik/FormikInput';
import { Button } from '../../../components';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { FormikSelectInput } from '../../../components/Form/Formik/FormikSelect';
import { useHistory } from 'react-router-dom';
import Modal from '../../../components/Modal';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const formInitialValues = {};

const countryCode = {
  norway: 'NO',
  sweden: 'SE'
};

const responseTypes = {
  success: 0,
  error: 1
};

const Customer = ({ match }) => {
  const [customer, setCustomer] = useState(null);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [countries, setCountries] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [externalProviderCompanyData, setExternalProviderCompanyData] = useState(null);

  let history = useHistory();
  const customerAction = useToastAction();
  const updateCustomerAction = useToastAction();
  const countriesAction = useToastAction();
  const externalProviderDataAction = useToastAction();
  const externalProviderFulFillAction = useToastAction();

  const fetchCountries = () => {
    countriesAction.execute(async () => {
      const response = await getCountries();

      setCountries(response);
    }, 'Failed to load data');
  };

  const fetchCustomer = () => {
    customerAction.execute(async () => {
      const getCustomersResult = await getCustomer(match.params.customerId);

      setCustomer(getCustomersResult);
      setFormValues({
        name: getCustomersResult.name,
        orgNumber: getCustomersResult.orgNumber,
        status: getCustomersResult.activationStatus,
        country: getCustomersResult.country.code,
        firstName: getCustomersResult.contactPerson
          ? getCustomersResult.contactPerson.firstName
          : '',
        title: getCustomersResult.contactPerson ? getCustomersResult.contactPerson.title : '',
        lastName: getCustomersResult.contactPerson ? getCustomersResult.contactPerson.lastName : '',
        email: getCustomersResult.contactPerson ? getCustomersResult.contactPerson.email : '',
        phone: getCustomersResult.contactPerson ? getCustomersResult.contactPerson.phone : '',
        billingAddress: getCustomersResult.billingAddress ? getCustomersResult.billingAddress : {},
        visitingAddress: getCustomersResult.visitingAddress
          ? getCustomersResult.visitingAddress
          : {}
      });
    }, 'Failed to load data');
  };

  const handleOnSubmit = (values, actions) => {
    updateCustomerAction.execute(
      async () => {
        const countryObject = countries.find((c) => c.code === values.country);

        await saveCustomer({
          customerId: customer.customerId,
          name: values.name,
          activationStatus: values.status,
          orgNumber: values.orgNumber,
          country: countryObject,
          contactPerson: {
            title: values.title,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone
          },
          billingAddress: { ...values.billingAddress },
          visitingAddress: { ...values.visitingAddress }
        });

        actions.setSubmitting(false);
      },
      'Failed to save data',
      'Save successful'
    );
  };

  const handleOnCancel = () => {
    history.push('/admin/approvals');
  };

  const handleOnExternalProviderCheckClick = (values) => {
    externalProviderDataAction.execute(async () => {
      const externalProviderCustomerData = await getExternalProviderCustomerData(values.orgNumber);

      if (externalProviderCustomerData.type === responseTypes.success) {
        setFormValues(values);
        setExternalProviderCompanyData(JSON.parse(externalProviderCustomerData.data));
        setShowModal(true);
      } else {
        toast.error('There are not data found for provided organization number ');
      }
    }, 'Failed to data');
  };

  const getStreetAddress = (address) => {
    if (address?.length > 0) {
      if (address[0].includes('c/o') && address[1]) {
        return address[1];
      } else {
        return address[0];
      }
    }
  };

  const fulFillFromExternalProvider = (values) => {
    externalProviderFulFillAction.execute(async () => {
      const getBrregFulfillResponse = await getBrregFulfill(values.orgNumber);

      if (getBrregFulfillResponse.type === responseTypes.success) {
        setFormValues({
          ...values,
          name: getBrregFulfillResponse.data.name,
          billingAddress: {
            ...formValues.billingAddress,
            ...getBrregFulfillResponse.data?.registrationAddress,
            region: getBrregFulfillResponse.data?.registrationAddress?.state,
            streetName: getStreetAddress(getBrregFulfillResponse.data?.registrationAddress?.address)
          },
          visitingAddress: {
            ...formValues.visitingAddress,
            ...getBrregFulfillResponse.data?.officeAddress,
            region: getBrregFulfillResponse.data.officeAddress?.state,
            streetName: getStreetAddress(getBrregFulfillResponse.data?.officeAddress?.address)
          }
        });
      } else {
        toast.error('Failed to load data');
      }
      setShowModal(false);
    }, 'Failed to load data');
  };

  useEffect(() => {
    fetchCountries();
    fetchCustomer();
  }, []);

  return (
    <>
      <div className="space-y-8">
        <PageHeader title={'Company - ' + formValues.name} />
        <div className="flex gap-10">
          <MenuBarDesktopLink isActive={true}>
            <Link
              to={'/admin/approvals/' + match.params.customerId + '/settings'}
              className="font-medium text-sm border-0 shadow-none"
            >
              Settings
            </Link>
          </MenuBarDesktopLink>
          <MenuBarDesktopLink isActive={false}>
            <Link
              to={'/admin/approvals/' + match.params.customerId + '/contacts'}
              className="font-medium text-sm border-0 shadow-none"
            >
              Contacts
            </Link>
          </MenuBarDesktopLink>
        </div>

        {customer && countries.length > 0 && (
          <div className="bg-white py-6 px-4 sm:p-6 shadow sm:rounded-md sm:overflow-hidden">
            <Formik initialValues={formValues} onSubmit={handleOnSubmit} enableReinitialize={true}>
              {(props) => (
                <Form>
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="md:grid md:grid-cols-4 md:gap-6">
                      <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Company</h3>
                      </div>

                      <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-7">
                          <div className="sm:col-span-2">
                            <FormikInput label="Company name" name="name" formikProps={props} />
                          </div>

                          <div className="sm:col-span-2">
                            <FormikInput
                              label="Organization number"
                              name="orgNumber"
                              formikProps={props}
                            />
                          </div>

                          <div className="sm:col-span-3"></div>

                          <div className="sm:col-span-1">
                            <FormikSelectInput
                              label="State"
                              name="status"
                              options={[
                                {
                                  name: 'Pending',
                                  value: '1'
                                },
                                {
                                  name: 'Active',
                                  value: '2'
                                },
                                {
                                  name: 'Rejected',
                                  value: '3'
                                }
                              ]}
                              formikProps={props}
                            />
                          </div>

                          <div className="sm:col-span-1">
                            <FormikSelectInput
                              label="Country"
                              name="country"
                              options={countries.map((c) => ({
                                name: c.name,
                                value: c.code
                              }))}
                              formikProps={props}
                            />
                          </div>

                          <div className="flex items-end sm:col-span-3">
                            {props.values.country === countryCode.norway && (
                              <Button
                                variant={Button.variants.primary}
                                onClick={() => handleOnExternalProviderCheckClick(props.values)}
                              >
                                Check in Extenral Provider
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-8 md:grid md:grid-cols-4 md:gap-6">
                      <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Billing address
                        </h3>
                      </div>

                      <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-7">
                          <div className="sm:col-span-5">
                            <FormikInput
                              label="Street address"
                              name="billingAddress.streetName"
                              formikProps={props}
                            />
                          </div>

                          <div className="sm:col-span-1"></div>

                          <div className="sm:col-span-2">
                            <FormikInput
                              label="City"
                              name="billingAddress.city"
                              formikProps={props}
                            />
                          </div>

                          <div className="sm:col-span-3">
                            <FormikInput
                              label="State / Province"
                              name="billingAddress.region"
                              formikProps={props}
                            />
                          </div>

                          <div className="sm:col-span-1">
                            <FormikInput
                              label="Postal code"
                              name="billingAddress.postalCode"
                              formikProps={props}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-8 md:grid md:grid-cols-4 md:gap-6">
                      <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Visiting address
                        </h3>
                      </div>

                      <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-7">
                          <div className="sm:col-span-5">
                            <FormikInput
                              label="Street address"
                              name="visitingAddress.streetName"
                              formikProps={props}
                            />
                          </div>

                          <div className="sm:col-span-1"></div>

                          <div className="sm:col-span-2">
                            <FormikInput
                              label="City"
                              name="visitingAddress.city"
                              formikProps={props}
                            />
                          </div>

                          <div className="sm:col-span-3">
                            <FormikInput
                              label="State / Province"
                              name="visitingAddress.region"
                              formikProps={props}
                            />
                          </div>

                          <div className="sm:col-span-1">
                            <FormikInput
                              label="Postal code"
                              name="visitingAddress.postalCode"
                              formikProps={props}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-8 md:grid md:grid-cols-4 md:gap-6">
                      <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          User profile
                        </h3>
                      </div>

                      <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-7">
                          <div className="sm:col-span-1">
                            <FormikInput label="Title" name="title" formikProps={props} />
                          </div>

                          <div className="sm:col-span-2">
                            <FormikInput label="First name" name="firstName" formikProps={props} />
                          </div>

                          <div className="sm:col-span-2">
                            <FormikInput label="Last name" name="lastName" formikProps={props} />
                          </div>

                          <div className="sm:col-span-2"></div>

                          <div className="sm:col-span-3">
                            <FormikInput label="Email address" name="email" formikProps={props} />
                          </div>

                          <div className="sm:col-span-2">
                            <FormikInput label="Phone number" name="phone" formikProps={props} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-5">
                      <div className="flex justify-end">
                        <Button
                          variant={Button.variants.secondary}
                          onClick={() => handleOnCancel(props.resetForm)}
                        >
                          Cancel
                        </Button>

                        <FormikSubmitButton
                          id="save"
                          text="Save"
                          className="ml-2 i"
                          formikProps={props}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>

      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        size={'large'}
        title="Data from Brreg"
      >
        <div className="overflow-x-auto">
          <pre className="mt-6 text-sm">
            {JSON.stringify(externalProviderCompanyData, undefined, 2)}
          </pre>
        </div>
        <div className="flex justify-end mt-5 sm:mt-6">
          <Button
            variant={Button.variants.secondary}
            className="mr-3"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>

          <Button
            variant={Button.variants.primary}
            onClick={() => fulFillFromExternalProvider(formValues)}
          >
            Fulfill
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Customer;
