import React, { useEffect, useRef, useState } from 'react';
import DayPicker from 'react-day-picker';
import styles from './DateField.module.scss';
import 'react-day-picker/lib/style.css';
import { useClassNames } from '../../hooks/useClassNames';
import DateFieldYearMonthForm from './components/DateFieldYearMonthForm';
import DateFieldNavbar from './components/DateFieldNavbar';
import { DateTimeParser } from '@metaforcelabs/metaforce-core';

const DateField = (props) => {
  const currentYear = new Date().getFullYear();
  const fromMonth = new Date(currentYear - 140, 0);
  const toMonth = new Date(currentYear, 0);
  const dayPickerWrapperRef = useRef(null);
  const dayPickerRef = useRef(null);
  const [isShowingDayPicker, setIsShowingDayPicker] = useState(false);
  const [disabledDays, setDisabledDays] = useState();
  const [selectedDay, setSelectedDay] = useState(
    props.value ? DateTimeParser.toDateObject(props.value) : undefined
  );
  const classNames = useClassNames();

  const handleSetDisbaledDays = () => {
    const result = [];

    if (props.minDate) {
      result.push({
        before: new Date(DateTimeParser.toLocaleDateString(props.minDate))
      });
    }

    if (props.maxDate) {
      result.push({
        after: new Date(DateTimeParser.toLocaleDateString(props.maxDate))
      });
    }

    return result;
  };

  const handleYearMonthChange = (month) => {
    setSelectedDay(month);
  };

  const handleOnDayClick = (date) => {
    const dateValue = DateTimeParser.toDateObject(date);

    setIsShowingDayPicker(false);
    setSelectedDay(date);

    if (props.onChange) {
      const handleChangeEvent = {
        target: {
          id: props.name,
          name: props.name,
          value: dateValue
        }
      };

      props.onChange(dateValue, handleChangeEvent);
    }
  };

  const handleClick = (e) => {
    if (dayPickerWrapperRef.current && dayPickerWrapperRef.current.contains(e.target)) {
      if (!(dayPickerRef.current && dayPickerRef.current.contains(e.target))) {
        setIsShowingDayPicker((prev) => !prev);
      }
    } else {
      setIsShowingDayPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleClick);

    return () => {
      document.removeEventListener('mouseup', handleClick);
    };
  });

  useEffect(() => {
    if (props.value) {
      setSelectedDay(DateTimeParser.toDateObject(props.value));
    }
  }, [props.value]);

  useEffect(() => {
    const disabledDaysResult = handleSetDisbaledDays();

    setDisabledDays(disabledDaysResult);
  }, [props.minDate, props.maxDate]);

  return (
    <>
      <div data-cy={props.dataCy} ref={dayPickerWrapperRef} className={styles.wrapper}>
        <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
          {props.label}
          {props.required === true && <span className="text-red-400"> *</span>}
        </label>
        <div className="mt-1">
          <input
            type="text"
            id={props.name}
            name={props.name}
            value={DateTimeParser.toLocaleDateString(props.value)}
            className={classNames.classNames(
              'shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400',
              props.disabled && styles.disabled
            )}
            disabled={props.disabled}
            readOnly
          />

          <div className="text-red-500">{props.error && <div>{props.error}</div>}</div>
        </div>

        {isShowingDayPicker && (
          <div ref={dayPickerRef} className={styles.dayPicker}>
            <DayPicker
              month={selectedDay}
              selectedDays={selectedDay}
              locale={props.locale || 'en'}
              disabledDays={disabledDays}
              captionElement={
                props.navbar
                  ? ({ date, localeUtils }) => (
                      <DateFieldYearMonthForm
                        fromMonth={fromMonth}
                        toMonth={toMonth}
                        date={date}
                        localeUtils={localeUtils}
                        onChange={handleYearMonthChange}
                      />
                    )
                  : undefined
              }
              navbarElement={props.navbar ? (props) => <DateFieldNavbar {...props} /> : undefined}
              onDayClick={handleOnDayClick}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DateField;
