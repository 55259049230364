import React, { useEffect, useState } from 'react';
import {
  getAvailableProductsApi,
  getCustomerLicenseApi,
  getCustomersApi,
  getPackagesApi
} from '../../../api/license';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import DateTimeParser from '../../../utils/DateTimeParser';
import CustomerList from './components/CustomerList';
import CustomerLicense from './components/CustomerLicense';
import { Loader } from '../../../components';

const License = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [availableProducts, setAvailableProducts] = useState(null);
  const [availablePackages, setAvailablePackages] = useState(null);
  const [customer, setCustomer] = useState(null);

  const getCustomerLicenseAction = useToastAction();

  const fetchCustomers = getCustomersApi;
  const fetchAvailableProducts = getAvailableProductsApi;
  const fetchPackages = getPackagesApi;

  const fetchCustomerLicense = async (customerId, packagesRes) => {
    getCustomerLicenseAction.execute(async () => {
      const customerLicenseApiResponse = await getCustomerLicenseApi(customerId);

      if (customerLicenseApiResponse.plans) {
        let customerLicenseCurrent = customerLicenseApiResponse.plans.find((r) => {
          if (
            r.expirationDate &&
            !DateTimeParser.isAfter(r.subscriptionDate, 'second') &&
            DateTimeParser.isAfter(r.expirationDate, 'second')
          ) {
            return true;
          } else if (r.expirationDate === null) {
            return true;
          }

          return false;
        });

        if (!customerLicenseCurrent && packagesRes) {
          customerLicenseCurrent = packagesRes.sort((a, b) => a.tierLevel - b.tierLevel)[0];
        }

        const customerLicenseNext = customerLicenseApiResponse.plans.find((r) => {
          if (
            DateTimeParser.isSame(
              r.subscriptionDate,
              customerLicenseCurrent.expirationDate,
              'second'
            )
          ) {
            return true;
          }

          return false;
        });

        setCustomer({
          activeUsers: customerLicenseApiResponse.numberOfActiveUsers,
          license: customerLicenseCurrent,
          nextLicense: customerLicenseNext
        });
      }
    }, 'Failed');
  };

  const onLoadAllData = () => {
    Promise.all([fetchCustomers(), fetchPackages(), fetchAvailableProducts()]).then(
      async (result) => {
        const customerRes = result[0];
        const packagesRes = result[1];
        const availableProductsRes = result[2];
        const sortedCustomer = customerRes.sort((a, b) => a.name.localeCompare(b.name));

        await fetchCustomerLicense(sortedCustomer[0].customerId, packagesRes);
        setCustomers(customerRes);
        setAvailableProducts(availableProductsRes);
        setAvailablePackages(packagesRes);
        setSelectedCustomer(sortedCustomer[0]);
      },
      'Failed to load data'
    );
  };

  const handleOnSelectCustomer = async (selectedCustomer) => {
    await fetchCustomerLicense(selectedCustomer.customerId, availablePackages);

    setSelectedCustomer(selectedCustomer);
  };

  useEffect(() => {
    onLoadAllData();
  }, []);

  return (
    <div>
      <div className="flex flex-col pb-5 mb-10 border-b border-gray-200">
        <h1 className="text-3xl font-bold">List of licenses</h1>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">Check current license of customer</p>
      </div>

      <div className="mx-auto lg:grid  lg:grid-cols-12 lg:gap-8">
        <div className="mb-4 lg:mb-0 lg:col-span-3 lg:block xl:col-span-3 py-5 bg-white shadow shadow-sm rounded-lg">
          {customers.length > 0 && (
            <CustomerList customers={customers} onSelectCustomer={handleOnSelectCustomer} />
          )}
        </div>

        <main className="relative lg:col-span-9 xl:col-span-9 p-5 bg-white shadow shadow-sm rounded-lg overflow-hidden">
          {getCustomerLicenseAction.isExecuting && (
            <Loader overlay className="absolute z-10 inset-0 flex items-center" />
          )}

          {selectedCustomer && customer && availablePackages && availableProducts && (
            <CustomerLicense
              selectedCustomer={selectedCustomer}
              customer={customer}
              products={availableProducts}
              packages={availablePackages}
            />
          )}
        </main>
      </div>
    </div>
  );
};

export default License;
