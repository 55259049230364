import { RedocStandalone } from 'redoc';
import { config } from '../../utils/config';

const DocumentationAPI = () => {
  return (
    <>
      <RedocStandalone
        specUrl={config.apiBaseUrl + '/swagger/v1/swagger.json'}
        options={{
          nativeScrollbars: true,
          theme: { colors: { primary: { main: '#dd5522' } } }
        }}
      />
    </>
  );
};

export default DocumentationAPI;
