import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import LoaderSpinner from 'react-loader-spinner';

export default function Loader({ className, overlay }) {
  return (
    <div
      className={className}
      style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
    >
      {overlay && <div className="absolute z-10 inset-0 bg-black bg-opacity-10"></div>}
      <LoaderSpinner type="Oval" color="#2b59c0" height={25} width={25} />
    </div>
  );
}
