import { ButtonV2, CoreButtonTypes, useToastAction } from '@metaforcelabs/metaforce-core';
import { useEffect, useState } from 'react';
import {
  getCompaniesSettingsApi,
  updateSettingsCompanyApprovals
} from '../../../api/companiesSettings';
import { Checkbox } from '../../../components/Form/Checkbox';
import React from 'react';
import { TextInput } from '../../../components/Form/TextInput';
import * as Yup from 'yup';
import { Formik } from 'formik';
import FormikInput from '../../../components/Form/Formik/FormikInput';
const Settings = () => {
  const [settings, setSettings] = useState({
    approvals: {
      activateCustomerAutomaticaly: false
    },
    customerCreatedWebhook: ''
  });

  const loadAction = useToastAction();
  const updateAction = useToastAction();

  const getCompaniesSettings = () => {
    loadAction.execute(async () => {
      const result = await getCompaniesSettingsApi();
      setSettings(result);
    }, 'Failed to load');
  };

  const handleSubmit = async (values) => {
    await updateAction.executeAsync(async () => {
      const updated = await updateSettingsCompanyApprovals({
        approvals: values.approvals,
        customerCreatedWebhook: values.customerCreatedWebhook
      });
      setSettings(updated);
    }, 'Failed to update', 'Company settings updated');
  }


  useEffect(() => {
    getCompaniesSettings();
  }, []);

  return (
    <>
      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 mt-5">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Companies settings</h3>
            </div>

            <Formik
              initialValues={settings}
              validationSchema={Yup.object().shape({
                approvals: Yup.object().shape({
                  activateCustomerAutomaticaly: Yup.boolean()
                }),
                customerCreatedWebhook: Yup.string().url("Must be valid URL").nullable()
              })}
              onSubmit={ async (values,props) => {
                console.log(props)
                props.setSubmitting(true)
                await handleSubmit(values)
                props.setSubmitting(false)
              }}
              enableReinitialize={true}
            >
              {(props) => {

                return (

                  <div>
                    <div className='mt-4 grid grid-cols-5 gap-6'>
                      <div className='col-span-full'>
                        <Checkbox
                          label="Active status by default"
                          checked={props.values.approvals.activateCustomerAutomaticaly}
                          name='approvals.activateCustomerAutomaticaly'
                          onChange={(checked) => {
                            props.handleChange({ target: { name: 'approvals.activateCustomerAutomaticaly', value: checked } });
                          }}
                        />
                      </div>
                      <div className='col-span-full sm:col-span-3'>
                        <FormikInput
                          formikProps={props}
                          name='customerCreatedWebhook'
                          label={'Company Created Webhook'}
                        />
                      </div>
                    </div>
                    <div className='mt-2 sm:mt-4 flex justify-end'>
                      <ButtonV2 type={CoreButtonTypes.cta}
                        label={'Save'}
                        htmlType='submit'
                        onClick={props.submitForm}
                        disabled={!props.isValid || props.isSubmitting}
                      />
                    </div>
                  </div>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
